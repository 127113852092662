import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersAllPurchaseOrders.css";

import {
  Catalogue,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";

function CompanyPurchaseOrdersWIPPurchaseOrders() {
  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  let pageNumber = 1;

  const [allIncomingPurchaseOrders, setAllIncomingPurchaseOrders] = useState<
    IncomingPurchaseOrderDataObject[]
  >([]);
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [isLoading, setIsLoading] = useState(true);

  const getAllWIPPurchaseOrdersPaginated = async (
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllWIPPurchaseOrdersPaginated(
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          28
        );
      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setAllIncomingPurchaseOrders((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error in fetching catalogues :", error);
      setIsLoading(false);
    }
  };

  const handleSearch = (query: string) => {
    setAllIncomingPurchaseOrders([]);
    setIsLoading(true);
    setSearchParams((prev) => {
      return {
        ...prev,
        item_count: 0,
        expected_item_count: 0,
        current_page_num: 1,
        search_string: query,
      };
    });
    getAllWIPPurchaseOrdersPaginated(query, 1, true);
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getAllWIPPurchaseOrdersPaginated(
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  useEffect(() => {
    getAllWIPPurchaseOrdersPaginated("", 1, false);
  }, []);

  useEffect(() => {
    console.log(searchParams);
  }, [searchParams]);

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  return (
    <div className="catalogues-container">
      <div className="catalogues-search-bar-container">
        <CompanyPurchaseOrdersAllPurchaseOrdersSearchBar
          onSearch={handleSearch}
        />
      </div>
      {allIncomingPurchaseOrders.length > 0 ? (
        <div className="catalogues-table-container">
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>PO Number</th>
                <th>Company Name</th>
                <th>Delivery Location</th>
                <th>PO status</th>
                <th>Number of SKUs</th>
                <th>Total Demand</th>
                <th>Pending Demand</th>
                <th>Total Packed</th>
                <th>Total Dispatched</th>
                <th>Expiry Date</th>
                <th>Creation Date</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>
              {allIncomingPurchaseOrders.map((purchase_order_item, index) => {
                return (
                  <tr className="catalogues-search-bar-row" key={index}>
                    <td className="po-id-link">
                      <a
                        href={`/incomingPos/info/details/${purchase_order_item.po_number}`}
                      >
                        {purchase_order_item.po_number}
                      </a>
                    </td>
                    <td>{purchase_order_item.company_name}</td>
                    <td>{purchase_order_item.delivery_city}</td>
                    <td
                      className={getCalculatedPoStatusColor(
                        purchase_order_item.calculated_po_status
                      )}
                    >
                      {purchase_order_item.calculated_po_status}
                    </td>

                    <td>{purchase_order_item.sku_count}</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>
                      {DateTimeUtils.formatDateTime(
                        purchase_order_item.expiry_date
                      )}
                    </td>
                    <td>
                      {DateTimeUtils.formatDateTime(
                        purchase_order_item.created_at
                      )}
                    </td>
                    <td>{purchase_order_item.created_by}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {searchParams.item_count > 0 &&
            searchParams.item_count < searchParams.expected_item_count && (
              <div className="load-more-catalogues-button-container">
                <Button
                  variant="outline-primary"
                  className="load-more-catalogues-button"
                  onClick={() => loadMore()}
                >
                  Load More..
                </Button>
              </div>
            )}
        </div>
      ) : (
        <div className="purchase-orders-table-container no-item">
          {isLoading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <div>No WIP purchase orders were found.</div>
          )}
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersWIPPurchaseOrders;
