import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import {
  IncomingPurchaseOrder,
  IncomingPurchaseOrderListingDetailsDataObject,
} from "../../atomic_components/types";
import DateTimeUtils from "../../utils/DateTimeUtils";
import { grey } from "@mui/material/colors";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 50, // Adjusted for multi-line header
    paddingBottom: 30, // Space for footer
    paddingHorizontal: 10,
  },
  section: {
    margin: 10,
    padding: 0, // No padding for the section
    flexGrow: 1,
  },
  containerAllBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid black",
    fontSize: 10,
    textAlign: "center", // Center text for header row
  },
  containerBottomBorder: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    fontSize: 10,
    padding: 0, // No padding for the data rows
    textAlign: "center", // Center text for data rows
  },
  srNo: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  srNoHeading: {
    width: "5%",
    padding: 0, // No padding for the srNo column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSkuHeading: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  poSku: {
    width: "25%", // Adjusted width to accommodate the new empty column
    padding: 0, // No padding for the qty column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimary: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  companyCodePrimaryHeading: {
    width: "20%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  warehouseInventory: {
    width: "10%", // Adjusted width`
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
    backgroundColor: "grey",
  },
  warehouseInventoryHeading: {
    width: "10%", // Adjusted width
    padding: 0, // No padding for the unitPrice column
    borderRight: "1px solid black",
    textAlign: "center", // Center text
    paddingBottom: 8,
    paddingTop: 8,
  },
  demand: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 3,
    paddingTop: 3,
    textAlign: "center", // Center text
  },
  pendingDemandHeading: {
    width: "10%",
    padding: 0, // No padding for the amount column
    borderRight: "1px solid black",
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  emptyColumn: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    textAlign: "center", // Center text
    paddingBottom: 3,
    paddingTop: 3,
  },
  emptyColumnHeading: {
    width: "20%", // Added column with some width
    padding: 0, // No padding for the empty column
    paddingBottom: 8,
    paddingTop: 8,
    textAlign: "center", // Center text
  },
  headerContainer: {
    textAlign: "center", // Center all text in header
    marginBottom: 10, // Adjust spacing between header lines
  },
  headerTextBold: {
    fontSize: 14,
    fontWeight: "bold", // Bold text for company name
    textAlign: "center", // Center company name
    marginBottom: 20,
  },
  headerText: {
    fontSize: 10,
    textAlign: "center", // Center other lines
  },
  headerTextQty: {
    fontSize: 10,
    textAlign: "center", // Center other lines
    marginTop: 10,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center", // Center text for footer
    fontSize: 10,
    padding: 10, // Keep padding for the footer text
  },
  totalSkusPendingContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 10,
    fontSize: 10,
  },
  totalSkusText: {
    fontWeight: "bold",
    marginRight: 20, // Space between total SKUs and pending quantity
  },
  totalPendingText: {
    fontWeight: "bold",
  },
  smallText: {
    fontSize: 8,
    textAlign: "right", // Align text to the right
    marginRight: 12,
  },
});

const CompanyPurchaseOrderPendingDispatchPDF: React.FC<{
  currentPurchaseOrder: IncomingPurchaseOrder;
  chunkedPurchaseOrderListingItem: IncomingPurchaseOrderListingDetailsDataObject[][];
  createdBy: String;
  totalSkus: number;
  totalPendingQuantity: number;
  chunkSize: number;
}> = ({
  currentPurchaseOrder,
  chunkedPurchaseOrderListingItem,
  createdBy,
  totalSkus,
  totalPendingQuantity,
  chunkSize,
}) => {
  return (
    <Document>
      {chunkedPurchaseOrderListingItem.map(
        (currentPurchaseOrderListingDetails, chunkedIndex) => (
          <Page size="A4" style={styles.page}>
            {/* Header */}
            <View style={styles.headerContainer}>
              {chunkedIndex === 0 ? ( // Only for the first page
                <>
                  <Text style={styles.headerTextBold}>
                    {currentPurchaseOrder.company.company_name}
                  </Text>
                  <Text style={styles.headerText}>
                    PO Number: {currentPurchaseOrder.po_number} | Location:{" "}
                    {currentPurchaseOrder.delivery_city}
                  </Text>
                  <Text style={styles.headerText}>
                    PO Date:{" "}
                    {DateTimeUtils.formatDateOnly(
                      currentPurchaseOrder.created_at
                    )}{" "}
                    | Expiry Date:{" "}
                    {DateTimeUtils.formatDateOnly(
                      currentPurchaseOrder.expiry_date
                    )}
                  </Text>
                  <Text style={styles.headerText}>
                    PDF created by: {createdBy} | PDF created at:{" "}
                    {DateTimeUtils.getCurrentDateTime()}
                  </Text>
                  <Text style={styles.headerTextQty}>
                    Total SKUs: {totalSkus} | Total Pending Quantity:{" "}
                    {totalPendingQuantity}
                  </Text>
                </>
              ) : (
                // For other pages, show minimal info
                <>
                  <Text style={styles.smallText}>
                    {currentPurchaseOrder.company.company_name}
                  </Text>
                  <Text style={styles.smallText}>
                    PO Number: {currentPurchaseOrder.po_number}
                  </Text>
                  <Text style={styles.smallText}>
                    Location: {currentPurchaseOrder.delivery_city}
                  </Text>
                </>
              )}
            </View>

            {/* Body */}
            <View style={styles.section}>
              <View style={styles.containerAllBorder}>
                <View style={styles.srNoHeading}>
                  <Text>#</Text>
                </View>
                <View style={styles.poSkuHeading}>
                  <Text>PO SKU</Text>
                </View>
                <View style={styles.companyCodePrimaryHeading}>
                  <Text>Company Code Primary</Text>
                </View>
                <View style={styles.warehouseInventoryHeading}>
                  <Text>Warehouse Inventory</Text>
                </View>
                <View style={styles.pendingDemandHeading}>
                  <Text>Pending Demand</Text>
                </View>
                <View style={styles.emptyColumnHeading}>
                  <Text> </Text> {/* Empty column */}
                </View>
              </View>

              {currentPurchaseOrderListingDetails.map((singleItem, index) => (
                <View key={index} style={styles.containerBottomBorder}>
                  <View style={styles.srNo}>
                    <Text>{chunkedIndex * chunkSize + index + 1}</Text>
                  </View>
                  <View style={styles.poSku}>
                    <Text>{singleItem.po_secondary_sku}</Text>
                  </View>
                  <View style={styles.companyCodePrimary}>
                    <Text>{singleItem.company_code_primary}</Text>
                  </View>
                  <View style={styles.warehouseInventory}>
                    <Text>{singleItem.listing.available_quantity}</Text>
                  </View>
                  <View style={styles.demand}>
                    <Text>{singleItem.demand}</Text>
                  </View>
                  <View style={styles.emptyColumn}>
                    <Text> </Text> {/* Empty column */}
                  </View>
                </View>
              ))}
            </View>

            {/* Footer */}
            <Text
              style={styles.footer}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
            />
          </Page>
        )
      )}
    </Document>
  );
};

export default CompanyPurchaseOrderPendingDispatchPDF;
