import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import "./CSS/CompanyPurchaseOrdersAllPurchaseOrders.css";

import {
  Catalogue,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderDataObject,
  PurchaseOrder,
  SearchParams,
} from "../../atomic_components/types";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrdersAllPurchaseOrdersSearchBar from "./CompanyPurchaseOrdersAllPurchaseOrdersSearchBar";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import { AxiosError } from "axios";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrdersDocuments() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );
  const { po_id } = useParams<RouteParams>();

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(false);
  const [createNewPackTask, setCreateNewPackTask] = useState(false);

  const createNewPackTaskFunc = async (purchase_order_id: any) => {
    setShowLoadingPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.createNewPackTask(
        purchase_order_id.toString(),
        userDetailsFromStore.user_details.userId
      );

      toast.success("Pack Task created successfully", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      setShowLoadingPage(false);
    } catch (error) {
      console.error("Error in creating new pack task : ", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
    }
  };

  return (
    <div className="catalogues-container">
      <div className="vendor-action-buttons-container incoming-purchase-order-details-table-container pt-4">
        <Button
          variant="outline-primary"
          className="vendor-action-button"
          onClick={() => setCreateNewPackTask(true)}
        >
          Create a new Pack Task.
        </Button>
      </div>
      <Modal
        show={createNewPackTask}
        onHide={() => setCreateNewPackTask(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Pack Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please note that a pack task can only be created once a PO is marked
          as WIP.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCreateNewPackTask(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => createNewPackTaskFunc(po_id)}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      {showLoadingPage && (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      )}
    </div>
  );
}

export default CompanyPurchaseOrdersDocuments;
