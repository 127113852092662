import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CompanyPurchaseOrdersDetails.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
  FloatingLabel,
  Form,
  Fade,
} from "react-bootstrap";

import {
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderAnalyticsObject,
  IncomingPurchaseOrderListingDetailsDataObject,
  LabelData,
  SearchParams,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";
import { PDFDocument, rgb, degrees } from "pdf-lib";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import CompanyPurchaseOrderLabelUtils from "./LabelUtils/CompanyPurchaseOrderLabelUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";

import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import CompanyPurchaseOrderPendingDispatchPDF from "./CompanyPurchaseOrderPendingDispatchPDF";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";

interface RouteParams {
  po_id: string;
}

interface RadioOption {
  label: string;
  value: string;
}

enum LabelSize {
  SeventyForty = "70By40",
  SeventyFiveThirtyEight = "75By38",
}

enum MarketedBy {
  EunoiaCrafts = "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Intellozene = "Intellozene, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Flipkart = "M/s Flipkart India Private Limited, Buildings Alyssa",
}

enum ManufacturedBy {
  EunoiaCrafts = "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
  Intellozene = "Intellozene, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
}

function CompanyPurchaseOrdersDetails() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const [selectedLabelSize, setSelectedLabelSize] = useState<string>(
    LabelSize.SeventyForty
  );

  const handleDataValidation = async () => {
    // try {
    //   // Simulating a network call to check data
    //   const response = await fetch('/validate-data-endpoint');
    //   const data = await response.json();

    //   if (data.isValid) {
    //     setIsDataValid(true);
    //     setStep(2); // Move to next step if data is valid
    //   } else {
    //     alert('Data is not valid');
    //   }
    // } catch (error) {
    //   console.error('Error validating data', error);
    // }

    setStep(2);
  };

  const handleNextStep = () => {
    if (step === 1) {
      handleDataValidation(); // Validate data on the first step
    } else if (step === 2) {
      setStep(3); // Move to the final step (label size selection)
    }
  };

  const handleBackStep = () => {
    setStep(step - 1); // Go back to the previous step
  };

  const handleCancel = () => {
    setGenerateProductLabels(false); // Close the modal
  };

  const labelSizes: RadioOption[] = [
    { label: "70 x 40 mm", value: LabelSize.SeventyForty },
    { label: "75 x 38 mm", value: LabelSize.SeventyFiveThirtyEight },
  ];

  const handleRadioChange = (value: string) => {
    setSelectedLabelSize(value);
    console.log(`Selected label size: ${value}`);
  };

  const [step, setStep] = useState(1); // Step state to control the modal step
  const [isDataValid, setIsDataValid] = useState(false); // Flag to check if the data is valid

  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);
  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [
    currentPurchaseOrderListingDetails,
    setCurrentPurchaseOrderListingDetails,
  ] = useState<IncomingPurchaseOrderListingDetailsDataObject[]>([]);
  const [
    currentPurchaseOrderAnalyticsObject,
    setCurrentPurchaseOrderAnalyticsObject,
  ] = useState<IncomingPurchaseOrderAnalyticsObject>();

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search_string: "",
    item_count: 0,
    expected_item_count: 0,
    current_page_num: 1,
  });

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [acknowledgePurchaseOrder, setAcknowledgePurchaseOrder] =
    useState(false);
  const [cancelPurchaseOrder, setCancelPurchaseOrder] = useState(false);
  const [workInProgress, setWorkInProgress] = useState(false);
  const [downloadDetailedReport, setDownloadDetailedReport] = useState(false);
  const [downloadPendencyPDF, setDownloadPendencyPDF] = useState(false);
  const [generateProductLabels, setGenerateProductLabels] = useState(false);
  const [generatePhase1BoxLabels, setGeneratePhase1BoxLabels] = useState(false);
  const [chunkedPurchaseOrderListingItem, setChunkedPurchaseOrderListingItem] =
    useState<IncomingPurchaseOrderListingDetailsDataObject[][]>([]);

  const [startingPhase1BoxNumber, setStartingPhase1BoxNumber] = useState(0);
  const [endingPhase1BoxNumber, setEndingPhase1BoxNumber] = useState(0);
  const [selectedMarketedBy, setSelectedMarketedBy] = useState<string>(
    MarketedBy.EunoiaCrafts
  );
  const [selectedManufacturedBy, setSelectedManufacturedBy] = useState<string>(
    ManufacturedBy.EunoiaCrafts
  );

  const [selectedManufacturedDate, setSelectedManufacturedDate] =
    useState<string>(DateTimeUtils.getCurrentMonthAndYear());
  const [selectedBrand, setSelectedBrand] = useState<string>("eCraftIndia");

  const [selectedCountryOfOrigin, setSelectedCountryOfOrigin] =
    useState<string>("eCraftIndia");

  const [showLabelGenerationModal, setShowLabelGenerationModal] =
    useState<boolean>(false);

  useEffect(() => {
    setChunkedPurchaseOrderListingItem(
      chunkArray(currentPurchaseOrderListingDetails, 30)
    );
  }, [currentPurchaseOrderListingDetails]);

  const chunkArray = (
    array: IncomingPurchaseOrderListingDetailsDataObject[],
    size: number
  ): IncomingPurchaseOrderListingDetailsDataObject[][] => {
    const sortedArray = [...array].sort((a, b) => {
      const diffA = a.demand - 0; //a.pending;
      const diffB = b.demand - 0; //b.pending;
      return diffB - diffA; // descending order
    });

    return Array.from(
      { length: Math.ceil(sortedArray.length / size) },
      (_, index) => sortedArray.slice(index * size, index * size + size)
    );
  };

  const toggleRow = (index: number) => {
    if (expandedRow === index) {
      setExpandedRow(null); // collapse if already expanded
    } else {
      setExpandedRow(index); // expand selected row
    }
  };

  const handleMarketedByChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedMarketedBy(event.target.value);
  };

  const handleManufacturedByChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedManufacturedBy(event.target.value);
  };

  const handleManufactureDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedManufacturedDate(event.target.value);
  };

  const handleBrandChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBrand(event.target.value);
  };

  const handleCountryOfOriginChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedCountryOfOrigin(event.target.value);
  };

  useEffect(() => {
    const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
            purchase_order_id.toString()
          );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderListingDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
            purchase_order_id.toString(),
            "",
            1,
            1000
          );

        setSearchParams((prev) => {
          return {
            ...prev,
            item_count: prev.item_count + response.data["curr_page_count"],
            expected_item_count: response.data["total"],
            current_page_num: response.data["current_page"],
          };
        });

        setCurrentPurchaseOrderListingDetails(response.data["content"]);

        setShowLoadingPage(false);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    const fetchPurchaseOrderAnalysisObject = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getPurchaseOrderAnalyticsObject(
            purchase_order_id.toString()
          );

        setCurrentPurchaseOrderAnalyticsObject(response.data);
      } catch (error) {
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPurchaseOrderDetails(po_id);
    fetchPurchaseOrderListingDetails(po_id);
    fetchPurchaseOrderAnalysisObject(po_id);
  }, []);

  const getPurchaseOrderListingDetailsPaginated = async (
    purchase_order_id: any,
    searchString: string,
    pageNumber: number,
    fromHandleSearch: boolean
  ) => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.getAllPurchaseOrdersListingPaginated(
          purchase_order_id.toString(),
          fromHandleSearch == true ? searchString : searchParams.search_string,
          pageNumber,
          1000
        );

      setSearchParams((prev) => {
        return {
          ...prev,
          item_count: prev.item_count + response.data["curr_page_count"],
          expected_item_count: response.data["total"],
          current_page_num: response.data["current_page"],
        };
      });

      setCurrentPurchaseOrderListingDetails((prevList) => [
        ...prevList,
        ...response.data["content"],
      ]);

      setShowLoadingPage(false);
    } catch (error) {
      toast.error("Error! Could not find purchase order details.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  };

  const loadMore = () => {
    console.log("FROM load more : ", searchParams.current_page_num);
    getPurchaseOrderListingDetailsPaginated(
      po_id,
      "",
      searchParams.current_page_num + 1,
      false
    );
    setSearchParams((prev) => {
      return {
        ...prev,
        current_page_num: prev.current_page_num + 1,
      };
    });
  };

  const getCalculatedPoStatusColor = (calculatedStatus: string) => {
    if (calculatedStatus === "ACKNOWLEDGEMENT PENDING")
      return "incoming-po-text-orange";
    else if (calculatedStatus === "OPEN") return "incoming-po-text-green";
    else if (calculatedStatus === "MARKED CANCELLED")
      return "incoming-po-text-purple";
    else if (calculatedStatus === "EXPIRED") return "incoming-po-text-red";
    return "";
  };

  const getWIPcolor = (wip_status: string) => {
    if (wip_status == "YES") return "marked-green";
    else return "marked-red";
  };

  const markForAcknowledgement = async () => {
    setShowLoadingPage(true);
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.acknowledgePurchaseOrder(
          po_id,
          userDetailsFromStore.user_details.userId
        );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Acknowledged successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setAcknowledgePurchaseOrder(false);
    }
  };

  const markForWIP = async () => {
    setShowLoadingPage(true);
    try {
      const response = await companyPurchaseOrderNetworkUtil.markForWIP(
        po_id,
        userDetailsFromStore.user_details.userId
      );
      setCurrentPurchaseOrder(response.data);
      toast.success("PO Marked WIP successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      setShowLoadingPage(false);
      setWorkInProgress(false);
    } catch (error) {
      console.error(
        "Error in marking purchase order for acknowledgement : ",
        error
      );
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }

      setShowLoadingPage(false);
      setWorkInProgress(false);
    }
  };

  const generatePDF = async (
    width: number,
    height: number,
    labelData: LabelData,
    labelHeadings: LabelData,
    labelCount: number
  ) => {
    const doc = new jsPDF({
      unit: "cm",
      format: [width, height],
      orientation: "portrait",
    });

    const fontSize = 5; // Further reduced font size for tighter fit
    doc.setFontSize(fontSize);

    // General margin and content settings
    const margin = 0.15; // Further reduced border margin
    const contentWidth = width - 2 * margin; // Available width within the border
    const textMargin = margin + 0.1; // Reduced margin between text elements
    const barcodeHeight = height / 7; // Adjusted barcode height for smaller label
    const cellHeight3Lines = 0.7; // Further reduced cell height to fit more content
    const cellHeight1Line = 0.3;

    for (let i = 0; i < labelCount; i++) {
      if (i > 0) doc.addPage(); // Add a new page for each label after the first one

      // Generate barcode
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, labelData.barcode, { format: "CODE128" });
      const barcodeImage = canvas.toDataURL("image/png");

      // Add barcode image at the top (centered within the border)
      const barcodeWidth = contentWidth - 0.8; // Adjust width for alignment
      const barcodeWidthNew = contentWidth;
      const barcodeX = (width - barcodeWidthNew) / 2; // Center barcode
      doc.addImage(
        barcodeImage,
        "PNG",
        barcodeX,
        margin,
        barcodeWidthNew,
        barcodeHeight
      );

      // Draw border around the entire label
      // doc.setLineWidth(0.05); // Reduced border thickness
      // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);

      // Add table data below the barcode
      const contentStartY = margin + barcodeHeight + 0.3; // Start just below the barcode
      const keys = Object.keys(labelData) as (keyof LabelData)[];
      const filteredKeys = keys.filter((key) => key !== "barcode");

      let y = contentStartY;
      filteredKeys.forEach((key, index) => {
        // Combine heading and value into a single wrapped text
        const fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;

        // Add wrapped text within the border
        doc.text(fullText, textMargin, y, {
          maxWidth: contentWidth - margin, // Ensure text wraps within the border
        });

        if (
          key === "marketedBy" ||
          key === "manufacturedBy" ||
          key === "oneSetContains"
        ) {
          y += cellHeight3Lines;
        } else {
          y += cellHeight1Line;
        }
      });
    }

    // Save the PDF as a Uint8Array
    doc.save("labels.pdf");
    // const pdfBytes = doc.output("arraybuffer");

    // // Rotate the pages using PDF-Lib
    // await rotatePDFPages(pdfBytes);
  };

  const rotatePDFPages = async (pdfBytes: ArrayBuffer) => {
    // Load the PDF into PDF-Lib
    const pdfDoc = await PDFDocument.load(pdfBytes);

    // Rotate each page
    const pages = pdfDoc.getPages();
    pages.forEach((page) => {
      page.setRotation(degrees(90)); // Rotate 90 degrees clockwise
    });

    // Save the rotated PDF
    const rotatedPdfBytes = await pdfDoc.save();

    // Download the rotated PDF
    const blob = new Blob([rotatedPdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "rotated_labels.pdf";
    link.click();

    URL.revokeObjectURL(url);
  };

  const handleLabelsGeneration = () => {
    const labelData: LabelData = {
      barcode: "RAKFR9AHPZZXBXBM",
      marketedBy: "M/S Flipkart India Private Limited, Buildings Alyssa",
      manufacturedBy:
        "Intellozene, 44, Jamna Dairy, Sodala, Jaipur, Rajasthan, India, 302006",
      dateOfManufacture: "Oct. 2024",
      color: "MultiColor",
      brand: "Flipkart",
      material: "Rakhi",
      netQuantity: "1 Set",
      productDimension: "10x10x5 cm",
      oneSetContains: "1N Rakhi with 1N Roli and 1N Chawal",
      modelNumber: "Flipkart Smartbuy D507",
      mrp: "1999",
      countryOfOrign: "Made in India",
      title: "eCraftIndia 50 pcs MultiColored Tealight Candle",
      styleId: "100910081007100600909",
    };

    const labelHeadings: LabelData = {
      barcode: "NA",
      marketedBy: "Marketed By/For Consumer Complaints",
      manufacturedBy: "Manufactured By",
      dateOfManufacture: "Date of Manufacture",
      color: "Color",
      brand: "Brand",
      material: "Material",
      netQuantity: "Net Quantity",
      productDimension: "Product Dimension",
      oneSetContains: "1 Set Contains",
      modelNumber: "Model Number",
      mrp: "Maximium Retail Price",
      countryOfOrign: "Country of Origin",
      title: "Title",
      styleId: "Style Id",
    };

    // Label size: 3.8x7.5 cm
    generatePDF(3.8, 7.5, labelData, labelHeadings, 100);
  };

  const handleLabelsGeneration2 = () => {
    const labelData: LabelData = {
      barcode: "RAKFR9AHPZZXBXBM",
      marketedBy:
        "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
      manufacturedBy:
        "ECIPL, Warehouse 1, Jaat Colony, Khasra 1660, Bhakrota, Jaipur, Rajasthan, 302026, Tel. no: 8100418100 & e-Mail: care@ecraftindia.com",
      title:
        "eCraftIndia 50 pcs MultiColored Tealight Candle with exquisite set of fragrances infused inside to light up your home.eCraftIndia 50 pcs MultiColored Tealight Candle with exquisite set of fragrances infused inside to light up your home.",
      dateOfManufacture: "Oct. 2024",
      color: "MultiColor",
      brand: "Flipkart",
      material: "Rakhi",
      netQuantity: "1 Set",
      oneSetContains: "1N Rakhi with 1N Roli and 1N Chawal",
      productDimension: "10x10x5 cm",
      modelNumber: "Flipkart Smartbuy D507",
      mrp: "1999",
      countryOfOrign: "India",
      styleId: "100910081007100600909",
    };

    const labelHeadings: LabelData = {
      barcode: "NA",
      marketedBy: "Marketed By/For Consumer Complaints",
      manufacturedBy: "Manufactured By",
      title: "Title",
      dateOfManufacture: "Date of Mfg.",
      color: "Color",
      brand: "Brand",
      material: "Material",
      netQuantity: "Net Quantity",
      oneSetContains: "1 Set Contains",
      productDimension: "Product Dimension",
      modelNumber: "Model Number",
      mrp: "Maximium Retail Price",
      countryOfOrign: "Country of Origin",
      styleId: "Style Id",
    };

    // Label size: 3.8x7.5 cm
    // CompanyPurchaseOrderLabelUtils.generateMyntraLabels75By38_v1(
    //   labelData,
    //   labelHeadings,
    //   5
    // );

    if (selectedLabelSize == LabelSize.SeventyFiveThirtyEight) {
      CompanyPurchaseOrderLabelUtils.generateMyntraLabels75By38_v2(
        labelData,
        labelHeadings,
        5
      );
    } else {
      CompanyPurchaseOrderLabelUtils.generateMyntraLabels70By40_v2(
        labelData,
        labelHeadings,
        5
      );
    }

    // generateMyntraLabels75By38_v2(labelData, labelHeadings, 5);
  };

  const handlePhase1BoxLabelsGeneration = () => {
    if (startingPhase1BoxNumber <= 0 || endingPhase1BoxNumber <= 0) {
      toast.error("Error! Page Numbers must be greater than 0.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      return;
    }

    if (startingPhase1BoxNumber > endingPhase1BoxNumber) {
      toast.error(
        "Error! Ending Box Number must be greater than startingBox Number.",
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return;
    }

    console.log(selectedLabelSize);

    if (selectedLabelSize == LabelSize.SeventyFiveThirtyEight) {
      CompanyPurchaseOrderLabelUtils.createPhase1BoxLabelsFor75by38(
        startingPhase1BoxNumber,
        endingPhase1BoxNumber,
        po_id
      );
    } else if (selectedLabelSize == LabelSize.SeventyForty) {
      CompanyPurchaseOrderLabelUtils.createPhase1BoxLabelsFor70by40(
        startingPhase1BoxNumber,
        endingPhase1BoxNumber,
        po_id
      );
    }
  };

  const downloadDetailedReportAsSpreadsheet = async () => {
    try {
      // itemsToExport.push()
      const transformedItems = currentPurchaseOrderListingDetails.map(
        (singleItem) => {
          return {
            po_secondary_sku: singleItem.po_secondary_sku,
            master_sku: singleItem.master_sku,
            inventory_sku_id: singleItem.inventory_sku_id,
            pack_combo_sku_id: singleItem.pack_combo_sku_id,
            sku_type: singleItem.sku_type,
            company_code_primary: singleItem.company_code_primary,
            company_code_secondary: singleItem.company_code_secondary,
            ops_tag: singleItem.listing.ops_tag,
            warehouse_quantity: singleItem.listing.available_quantity,
            demand: singleItem.demand,
            packed: 0,
            dispatched: 0,
            pending: 0,
            pending_percent: 0,
          };
        }
      );
      jsonexport(transformedItems, (err: Error | null, csv: string) => {
        if (err) {
          console.error(err);
          return;
        }
        // Create a Blob object with the CSV data
        const blob = new Blob([csv], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = currentPurchaseOrder
          ? currentPurchaseOrder.po_number + "_detailed_report.csv"
          : "po_detailed_report.csv";
        // Simulate a click to trigger the download
        link.click();
      });

      setDownloadDetailedReport(false);
    } catch (error) {
      console.log(
        "Printing error from CompanyPurchaseOrderDetails -> Download detailed report.   "
      );
      console.error("Error:", error);

      setDownloadDetailedReport(false);
    }
  };

  return (
    <div className="upload-purchase-order-details-container">
      {currentPurchaseOrder && (
        <div className="incoming-purchase-order-details-table-container pt-4">
          <Table striped bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Status</td>
                <td
                  className={getCalculatedPoStatusColor(
                    currentPurchaseOrder.calculated_po_status
                  )}
                >
                  {currentPurchaseOrder.calculated_po_status}
                </td>
              </tr>

              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Marked WIP ?</td>
                <td className={getWIPcolor(currentPurchaseOrder.is_wip)}>
                  {currentPurchaseOrder.is_wip}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer Company Name</td>
                <td>{currentPurchaseOrder.company.company_name}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">PO Number</td>
                <td>{currentPurchaseOrder.po_number}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Reference Location</td>
                <td>{currentPurchaseOrder.delivery_city}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Delivery Address</td>
                <td>{currentPurchaseOrder.delivery_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Billing Address</td>
                <td>{currentPurchaseOrder.billing_address}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Buyer GSTIN</td>
                <td>{currentPurchaseOrder.buyer_gstin}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Expiry Date</td>
                <td>
                  {DateTimeUtils.formatDateOnly(
                    currentPurchaseOrder.expiry_date
                  )}
                </td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created by</td>
                <td>{currentPurchaseOrder.created_by}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Created At</td>
                <td>
                  {DateTimeUtils.formatDateTime(
                    currentPurchaseOrder.created_at
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {currentPurchaseOrderAnalyticsObject && (
        <div className="incoming-purchase-order-details-table-container pt-4">
          <Table striped bordered hover size="sm" className="table-auto-width">
            <tbody>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Number of SKUs</td>
                <td>{currentPurchaseOrderAnalyticsObject.sku_count}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Demand</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_demand}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Pending</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_pending}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Packed</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_packed}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Total Dispatched</td>
                <td>{currentPurchaseOrderAnalyticsObject.total_dispatched}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Boxed Packed</td>
                <td>{currentPurchaseOrderAnalyticsObject.boxes_packed}</td>
              </tr>
              <tr className="catalogues-search-bar-row">
                <td className="nowrap">Boxes Dispatched</td>
                <td>{currentPurchaseOrderAnalyticsObject.boxes_dispatched}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      )}

      {currentPurchaseOrder && (
        <div className="vendor-action-buttons-container incoming-purchase-order-details-table-container pt-4">
          {currentPurchaseOrder.calculated_po_status ===
          "ACKNOWLEDGEMENT PENDING" ? (
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setAcknowledgePurchaseOrder(true)}
            >
              Acknowledge Purchase Order
            </Button>
          ) : (
            <div>
              {currentPurchaseOrder.calculated_po_status !==
                "MARKED CANCELLED" && (
                <div>
                  <Button
                    variant="outline-primary"
                    className="vendor-action-button"
                    onClick={() => setCancelPurchaseOrder(true)}
                  >
                    Cancel Purchase Order
                  </Button>

                  {currentPurchaseOrder.is_wip === "NO" && (
                    <Button
                      variant="outline-primary"
                      className="vendor-action-button"
                      onClick={() => setWorkInProgress(true)}
                    >
                      Add this purchase order to WIP
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}

          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setDownloadDetailedReport(true)}
          >
            Download detailed report
          </Button>
          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setDownloadPendencyPDF(true)}
          >
            Download pendency pdf
          </Button>
          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setGenerateProductLabels(true)}
          >
            Generate product labels
          </Button>
          <Button
            variant="outline-primary"
            className="vendor-action-button"
            onClick={() => setGeneratePhase1BoxLabels(true)}
          >
            Generate Phase 1 box labels
          </Button>
        </div>
      )}

      {showLoadingPage ? (
        <div className="loading-page">
          <Spinner animation="grow" />
          Loading, please wait...
        </div>
      ) : (
        currentPurchaseOrder && (
          <div className="po-items-container-details">
            <Table bordered hover size="sm">
              <thead className="sticky-header">
                <tr>
                  <th>sr_no.</th>
                  <th>image</th>
                  <th>po_secondary_sku</th>
                  <th>master_sku</th>
                  <th>inventory_sku_id</th>
                  <th>pack_combo_sku_id</th>
                  <th>sku_type</th>
                  <th>company_code_primary</th>
                  <th>warehouse_quantity</th>
                  <th>demand</th>
                  <th>packed</th>
                  <th>dispatched</th>
                  <th>pending</th>
                  <th>pending_percent</th>
                </tr>
              </thead>
              <tbody>
                {currentPurchaseOrderListingDetails.map(
                  (purchaseOrderItem, index) => {
                    const isExpanded = expandedRow === index;
                    return (
                      <React.Fragment key={index}>
                        <tr
                          className={
                            isExpanded
                              ? "incoming-po-search-bar-row expanded-main-row"
                              : "incoming-po-search-bar-row"
                          }
                          onClick={() => toggleRow(index)}
                          style={{ cursor: "pointer" }}
                        >
                          <td>{index + 1}</td>
                          <td>
                            <img
                              style={{ height: "70px", width: "70px" }}
                              src={purchaseOrderItem.listing.img_wdim}
                              alt="Product Image"
                            />
                          </td>
                          <td>{purchaseOrderItem.po_secondary_sku}</td>
                          <td>{purchaseOrderItem.master_sku}</td>
                          <td>{purchaseOrderItem.inventory_sku_id}</td>
                          <td>{purchaseOrderItem.pack_combo_sku_id}</td>
                          <td>{purchaseOrderItem.sku_type}</td>
                          <td>{purchaseOrderItem.company_code_primary}</td>
                          <td>
                            {purchaseOrderItem.listing.available_quantity}
                          </td>
                          <td>{purchaseOrderItem.demand}</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        {isExpanded && (
                          <tr
                            className={`incoming-po-search-bar-row expandable-content-po ${
                              isExpanded ? "expanded" : "collapsed"
                            }`}
                          >
                            <td></td>
                            <td colSpan={13} className="po-expanded-cell">
                              <div className="expanded-content">
                                <div className="expanded-item">
                                  <strong>Title:</strong>{" "}
                                  {purchaseOrderItem.title}
                                </div>
                                <div className="expanded-item">
                                  <strong>Company Code Secondary:</strong>{" "}
                                  {purchaseOrderItem.company_code_secondary}
                                </div>
                                <div className="expanded-item">
                                  <strong>HSN:</strong>{" "}
                                  {purchaseOrderItem.hsn_code}
                                </div>
                                <div className="expanded-item">
                                  <strong>MRP:</strong> {purchaseOrderItem.mrp}
                                </div>
                                <div className="expanded-item">
                                  <strong>NLC/TP:</strong>{" "}
                                  {purchaseOrderItem.nlc_tp}
                                </div>
                                <div className="expanded-item">
                                  <strong>Size:</strong>{" "}
                                  {purchaseOrderItem.size}
                                </div>
                                <div className="expanded-item">
                                  <strong>Color:</strong>{" "}
                                  {purchaseOrderItem.color}
                                </div>
                                <p className="expanded-additional">
                                  <em>Additional details for:</em>{" "}
                                  {purchaseOrderItem.po_secondary_sku}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  }
                )}
              </tbody>
            </Table>
            {searchParams.item_count > 0 &&
              searchParams.item_count < searchParams.expected_item_count && (
                <div className="load-more-catalogues-button-container">
                  <Button
                    variant="outline-primary"
                    className="load-more-catalogues-button"
                    onClick={() => loadMore()}
                  >
                    Load More..
                  </Button>
                </div>
              )}
          </div>
        )
      )}

      <Modal
        show={acknowledgePurchaseOrder}
        onHide={() => setAcknowledgePurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Acknowledge Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to acknowledge this Purchase Order ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setAcknowledgePurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForAcknowledgement()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={cancelPurchaseOrder}
        onHide={() => setCancelPurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancel Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Purchase Order ? Please note that
          if the pack task is open or the PO is in WIP state, it cannot be
          cancelled.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setCancelPurchaseOrder(false)}
          >
            Cancel
          </Button>
          <Button variant="primary">Yes</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={workInProgress}
        onHide={() => setWorkInProgress(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark this PO as Work in Progress(WIP)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to mark this Purchase Order as Work In Progress
          ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setWorkInProgress(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => markForWIP()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadDetailedReport}
        onHide={() => setDownloadDetailedReport(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Detailed Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This report contains the spreadsheet view of the table provided on
          this page.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDownloadDetailedReport(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => downloadDetailedReportAsSpreadsheet()}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={downloadPendencyPDF}
        onHide={() => setDownloadPendencyPDF(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Download Pendency PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This PDF holds the Pending Quantity of each sku that is yet to be
          dispatched.
          {currentPurchaseOrder && (
            <PDFDownloadLink
              document={
                <CompanyPurchaseOrderPendingDispatchPDF
                  currentPurchaseOrder={currentPurchaseOrder}
                  chunkedPurchaseOrderListingItem={
                    chunkedPurchaseOrderListingItem
                  }
                  createdBy={userDetailsFromStore.user_details.userId}
                  totalSkus={currentPurchaseOrderListingDetails.length}
                  totalPendingQuantity={currentPurchaseOrderListingDetails.reduce(
                    (total, cp) => total + cp.demand,
                    0
                  )}
                  chunkSize={30}
                />
              }
            >
              {({ blob, url, loading, error }) => (
                <Button className="vendor-action-button" disabled={loading}>
                  {loading ? "Loading document..." : "Download Invoice PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={generateProductLabels}
        onHide={() => setGenerateProductLabels(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Product Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Step 1: Data Validation */}
          {step === 1 && (
            <Fade in={step === 1}>
              <div>
                <p>Step 1: Verify data.</p>
                {/* Button to initiate the data validation */}
                <Button
                  variant="primary"
                  onClick={handleDataValidation}
                  disabled={isDataValid}
                >
                  {isDataValid ? "Data Verified" : "Verify Data"}
                </Button>
              </div>
            </Fade>
          )}

          {/* Step 2: Enter the Number of Labels */}
          {step === 2 && (
            <Fade in={step === 2}>
              <div>
                <p>Step 2: Modify fixed label settings.</p>
                <div className="fixed-label-details-form-container">
                  <div className="fixed-label-picker-container-main mt-3">
                    <div className="fixed-label-picker-selected-date-container ml-1 mb-1">
                      <span>Select Marketed By: </span> &nbsp;
                    </div>
                    <div className="maunfactured-marketed-by-container">
                      <select
                        value={selectedMarketedBy}
                        name="payment_mode"
                        className="fixed-label-picker-select"
                        onChange={handleMarketedByChange}
                      >
                        <option value={MarketedBy.EunoiaCrafts}>
                          {MarketedBy.EunoiaCrafts}
                        </option>
                        <option value={MarketedBy.Intellozene}>
                          {MarketedBy.Intellozene}
                        </option>
                        <option value={MarketedBy.Flipkart}>
                          {MarketedBy.Flipkart}
                        </option>
                      </select>
                    </div>

                    {/* <div className="other-option-container mt-2">
                      <label>
                        <input
                          type="checkbox"
                          checked={isOtherChecked}
                          onChange={handleOtherCheckboxChange}
                        />{" "}
                        Other
                      </label>
                      {isOtherChecked && (
                        <div className="custom-input-container mt-1">
                          <input
                            type="text"
                            value={customMarketedBy}
                            onChange={handleCustomInputChange}
                            placeholder="Enter custom marketed by..."
                            className="custom-input-field"
                          />
                        </div>
                      )}
                    </div> */}
                  </div>
                  <div className="fixed-label-picker-container-main mt-3">
                    <div className="fixed-label-picker-selected-date-container ml-1 mb-1">
                      <span>Select Manufactured By: </span> &nbsp;
                    </div>
                    <div className="maunfactured-marketed-by-container">
                      <select
                        value={selectedManufacturedBy}
                        name="payment_mode"
                        className="fixed-label-picker-select"
                        onChange={handleManufacturedByChange}
                      >
                        <option value={MarketedBy.EunoiaCrafts}>
                          {ManufacturedBy.EunoiaCrafts}
                        </option>
                        <option value={MarketedBy.Intellozene}>
                          {ManufacturedBy.Intellozene}
                        </option>
                      </select>
                    </div>
                  </div>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Date of Manufacture"
                    className="mb-3 mt-3"
                  >
                    <Form.Control
                      type="text"
                      name="manufactured_date"
                      value={selectedManufacturedDate}
                      onChange={handleManufactureDateChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Brand"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="brand"
                      value={selectedBrand}
                      onChange={handleBrandChange}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Country of Origin"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      name="country_of_origin"
                      value={selectedCountryOfOrigin}
                      onChange={handleCountryOfOriginChange}
                    />
                  </FloatingLabel>
                </div>
              </div>
            </Fade>
          )}

          {step === 3 && (
            <Fade in={step === 3}>
              <div>
                <p>Step 3: Enter the number of labels.</p>
              </div>
            </Fade>
          )}

          {/* Step 3: Select Label Size */}
          {step === 4 && (
            <Fade in={step === 4}>
              <div className="label-count-container mt-4">
                <div className="label-count-section-header mb-2">
                  Label Size Settings
                </div>
                {labelSizes.map((size) => (
                  <div key={size.value} className="form-check">
                    <input
                      type="radio"
                      id={`label-size-${size.value}`}
                      name="labelSize"
                      value={size.value}
                      checked={selectedLabelSize === size.value}
                      onChange={() => handleRadioChange(size.value)}
                      className="form-check-input"
                    />
                    <label
                      htmlFor={`label-size-${size.value}`}
                      className="form-check-label"
                    >
                      {size.label}
                    </label>
                  </div>
                ))}
              </div>
            </Fade>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>

          {/* Back Button for Steps 2 and 3 */}
          {step > 1 && (
            <Button variant="secondary" onClick={handleBackStep}>
              Back
            </Button>
          )}

          {/* Next Button for Step 1 and 2 */}
          {step < 3 && (
            <Button variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          )}

          {/* Generate Labels Button on the last step */}
          {step === 3 && (
            <Button variant="primary" onClick={handleLabelsGeneration2}>
              Generate Labels
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={generatePhase1BoxLabels}
        onHide={() => setGeneratePhase1BoxLabels(false)}
        className="alert-dialog"
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Generate Phase 1 Box Labels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="label-count-container mt-4">
            <div className="label-count-section-header mb-2">
              Label Count Settings
            </div>
            <FloatingLabel
              controlId="floatingInput"
              label="Enter the starting box number."
              className="mb-3"
            >
              <Form.Control
                type="number"
                value={startingPhase1BoxNumber}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0; // Convert to number
                  setStartingPhase1BoxNumber(value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Enter the ending box number."
              className="mb-3"
            >
              <Form.Control
                type="number"
                value={endingPhase1BoxNumber}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10) || 0; // Convert to number
                  setEndingPhase1BoxNumber(value);
                }}
              />
            </FloatingLabel>
          </div>

          <div className="label-count-container mt-4">
            <div className="label-count-section-header mb-2">
              Label Size Settings
            </div>
            {labelSizes.map((size) => (
              <div key={size.value} className="form-check">
                <input
                  type="radio"
                  id={`label-size-${size.value}`}
                  name="labelSize"
                  value={size.value}
                  checked={selectedLabelSize === size.value}
                  onChange={() => handleRadioChange(size.value)}
                  className="form-check-input"
                />
                <label
                  htmlFor={`label-size-${size.value}`}
                  className="form-check-label"
                >
                  {size.label}
                </label>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setGenerateProductLabels(false)}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePhase1BoxLabelsGeneration}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CompanyPurchaseOrdersDetails;
