import React, { useEffect, useState } from "react";
import CompanyPurchaseOrdersCompanyNamesDropdown from "./CompanyPurchaseOrdersCompanyNamesDropdown";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import { useHistory, useLocation } from "react-router-dom";
import {
  Company,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  PurchaseOrder,
  Vendor,
} from "../../atomic_components/types";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import Datetime from "react-datetime";
import DatePicker from "react-modern-calendar-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./CSS/CompanyPurchaseOrdersAdd.css";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { set } from "lodash";
import CompanyPurchaseOrdersCustomDateTimePicker from "./CompanyPurchaseOrdersCustomDateTimePicker";
import CompnayPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";
import { useAppSelector } from "../../state/hooks";
import { toast } from "react-toastify";
import DateTimeUtils from "../../utils/DateTimeUtils";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import CompanyPurchaseOrdersCustomDatePicker from "./CompanyPurchaseOrdersCustomDatePicker";
import { AxiosError } from "axios";

function CompanyPurchaseOrdersAdd() {
  const history = useHistory();
  const location = useLocation();
  const vendorsNetworkUtil = new VendorsNetworkUtil(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const incomingPurchaseOrdersNetworkUtil =
    new CompnayPurchaseOrderNetworkUtils(
      "https://your-api-endpoint.com",
      history,
      location
    );
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  useEffect(() => {
    const fetchAllActiveCompanies = async () => {
      try {
        const response =
          await incomingPurchaseOrdersNetworkUtil.getAllActiveCompanies();
        console.log(response.data);
        setCompanies(response.data);
      } catch (error) {
        console.error("Error in fetching all active companies", error);
      }
    };

    fetchAllActiveCompanies();
  }, []);

  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);

  const handleCompanyNameFromDropdownSelected = (value: Company) => {
    console.log("Selected Value:", value.company_name);
    setSelectedCompany(value);
  };

  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploadedFiles = event.target.files;
    if (uploadedFiles) {
      const fileArray = Array.from(uploadedFiles);
      if (fileArray.length + files.length > 2) {
        alert("You can upload a maximum of 2 files.");
      } else {
        setFiles([...files, ...fileArray]);
      }
    }
  };

  const [poNumber, setPoNumber] = useState<string>("");
  const [poLocation, setPoLocation] = useState<string>("");
  const [poBillingAddress, setPoBillingAddress] = useState<string>("");
  const [poShippingAddress, setPoShippingAddress] = useState<string>("");
  const [poBuyerGST, setPoBuyerGST] = useState<string>("");
  const [providedSelectedValue, setProvidedSelectedValue] = useState<Company>();

  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);

  const handleDateTimeChange = (newDateTime: Date | null) => {
    setSelectedDateTime(newDateTime);
  };

  const checkIfPoWasAlreadyCreated = async (po_id: string) => {
    try {
      const response =
        await incomingPurchaseOrdersNetworkUtil.geturchaseOrderByPOid(po_id);
      return true;
      // updateTransferListing(response.data["paths"], transferListing);
    } catch (error) {
      return false;
    }
  };

  const createPOAndProceed = async () => {
    // history.push(`/purchase-orders/add/addItems`);
    if (!selectedCompany) {
      toast.error("Error! Company not selected.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } else if (!selectedDateTime) {
      toast.error("Error! PO Expiry date not selected.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } else if (!poNumber || poNumber.length < 4) {
      toast.error(
        "Error! Invalid PO number, PO number must have at least 4 characters.",
        { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    } else if (!poBillingAddress || poBillingAddress.length == 0) {
      toast.error(
        "Error! Invalid PO billing address, Provide an appropriate billing address.",
        { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    } else if (!poShippingAddress || poShippingAddress.length == 0) {
      toast.error(
        "Error! Invalid PO shipping address, Provide an appropriate shipping address.",
        { position: toast.POSITION.TOP_RIGHT, hideProgressBar: true }
      );
    } else if (!poBuyerGST || poBuyerGST.length != 15) {
      toast.error("Error! Invalid GST number provided.", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    } else {
      // const durationInMilliseconds = (5 * 60 + 30) * 60 * 1000;
      // const newTimestamp = new Date().getTime() - durationInMilliseconds;
      // const currentDateUTC = new Date(newTimestamp);
      const currentDate = new Date();

      if (selectedDateTime <= currentDate) {
        toast.error("Invalid expiry date, Past dates are not supported.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        return;
      }

      if (files.length === 0) {
        toast.error("Error! Provide original PO files.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
        return;
      }
      if (await checkIfPoWasAlreadyCreated(poNumber)) {
        toast.error("Error! Provided PO already exists.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });

        return;
      }

      try {
        //uploading files first;
        try {
          const response =
            await incomingPurchaseOrdersNetworkUtil.uploadCompanyPurchaseOrderFiles(
              files,
              userDetailsFromStore.user_details.userId,
              poNumber
            );
          console.log(response);
          toast.success("Files successfully uploaded!", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
          // updateTransferListing(response.data["paths"], transferListing);
        } catch (error) {
          console.log(error);
          toast.error(
            "Unknown error while trying to upload files! Please try again.",
            {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            }
          );
          return;
        }
        // await delay(60000);

        const body = {
          company_id: selectedCompany.company_id,
          po_number: poNumber,
          delivery_city: poLocation,
          delivery_address: poShippingAddress,
          billing_address: poBillingAddress,
          buyer_gstin: poBuyerGST,
          expiry_date: DateTimeUtils.formatDateTimeForDb(selectedDateTime),
          po_creation_status: "PARTIAL",
          created_by: userDetailsFromStore.user_details.userId,
        };
        const response =
          await incomingPurchaseOrdersNetworkUtil.addNewPurchaseOrder(body);

        const addedPurchaseOrder: IncomingPurchaseOrder = response.data;
        history.push(
          `/incomingPos/addPo/addPoDetails/${addedPurchaseOrder.po_number}`
        );

        toast.success("PO created successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } catch (error) {
        const err = error as AxiosError;
        if (err.response) {
          if (err.response.data && err.response.status == 400) {
            toast.error((err.response.data as ErrorMessageBody).message, {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            });
          } else {
            toast.error("Error! Ensure no fields are left empty.", {
              position: toast.POSITION.TOP_RIGHT,
              hideProgressBar: true,
            });
          }
        }
      }
    }
  };

  return (
    <div className="pt-4 pb-4 main-container-purchase-order-add">
      <div className="form-container">
        <div className="dropdown-container mb-2">
          <CompanyPurchaseOrdersCompanyNamesDropdown
            options={companies}
            onItemSelected={handleCompanyNameFromDropdownSelected}
            providedSelectedValue={providedSelectedValue}
          />
        </div>
        {/* "E-Trade",
              "Swiggy",
              "Myntra",
              "Bigbasket",
              "FBF",
              "FBA",
              "Flipkart Alpha",
              "Flipkart Grocery",
              "A.Com",
              "Blinkit",
              "Vaaree",
              "Zepto",
              "D-Mart",
              "Jio-Mart", */}

        <FloatingLabel
          controlId="floatingInput"
          label="PO Number"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={poNumber}
            onChange={(e) => {
              setPoNumber(e.target.value);
            }}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="PO Location"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={poLocation}
            onChange={(e) => {
              setPoLocation(e.target.value);
            }}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Billing Address"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={poBillingAddress}
            onChange={(e) => {
              setPoBillingAddress(e.target.value);
            }}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Shipping Address"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={poShippingAddress}
            onChange={(e) => {
              setPoShippingAddress(e.target.value);
            }}
          />
        </FloatingLabel>

        <FloatingLabel
          controlId="floatingInput"
          label="Buyer GSTIN"
          className="mb-3"
        >
          <Form.Control
            type="text"
            value={poBuyerGST}
            onChange={(e) => {
              setPoBuyerGST(e.target.value);
            }}
          />
        </FloatingLabel>

        <div>
          <CompanyPurchaseOrdersCustomDatePicker
            onDateTimeChange={handleDateTimeChange}
          />
        </div>

        <div className="po-file-upload-container-main mt-3">
          <div className="date-time-picker-selected-date-container ml-1 mb-3">
            <span>Upload Original PO files : </span> <br></br>
            <span>
              (Maximum 2 files are allowed, Please upload both pdf and
              spreadsheet version of the PO.){" "}
            </span>{" "}
            &nbsp;
          </div>
          <input
            type="file"
            accept=".xls,.xlsx,.csv,.pdf"
            onChange={handleFileChange}
            multiple
          />

          {files.length > 0 && (
            <div>
              <div>Selected Files:</div>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="purchase-order-create-button-container mt-4">
          <Button
            className="purchase-order-create-button"
            variant="outline-primary"
            onClick={createPOAndProceed}
          >
            Submit
          </Button>
        </div>
        {/* <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        placeholderText="Select a date"
      /> */}
      </div>
    </div>
  );
}

export default CompanyPurchaseOrdersAdd;
