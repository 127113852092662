import React, { useEffect, useState } from "react";

import "./CSS/LabelsHome.css";

import { SearchParams } from "../../atomic_components/types";
import { AppDispatch } from "../../state";
import { useDispatch } from "react-redux";
import { ActiveTab__Action } from "../../state/action-creators";
import { useAppSelector } from "../../state/hooks";
import { useHistory, useParams } from "react-router-dom";
import Header from "../Header";
import LabelsCompany from "./LabelsCompany";

interface RouteParams {
  category: string; // Define the type for the parameter you're expecting
}

function ListingsHome() {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(ActiveTab__Action("inventory"));
  }, []);

  const { category } = useParams<RouteParams>();
  const [selectedItem, setSelectedItem] = useState(category);
  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    history.push(`/inventory/secondaryListings/${item}`);
  };

  useEffect(() => {
    setSelectedItem(category);
  }, [category]);

  return (
    <div className="overlay-inventory-home">
      <Header />
      <div className="nav-container">
        <nav className="navbar-inventory-home">
          <ul className="navbar-menu">
            <li
              className={`navbar-item-inventory-home ${
                selectedItem === "companyLabels" ? "active" : ""
              }`}
              onClick={() => handleItemClick("companyLabels")}
            >
              Company Labels
            </li>
          </ul>
        </nav>
      </div>

      {selectedItem == "companyLabels" && <LabelsCompany />}
    </div>
  );
}

export default ListingsHome;
