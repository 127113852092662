import axios, { AxiosError } from "axios";
import { requestHeaders, requests } from "../../../NetworkUtils/Requests";
import { AisListingUploadItem, PacksCombosUploadItem, PurchaseOrder, SecondaryListingUploadItem, SecondaryListings, Specialty, Vendor, VendorListing } from "../../../atomic_components/types";


class LabelsNetworkUtils {
  private api: any; // Define the Axios instance here

  constructor(baseURL: string, history: any, location:any) {
    // Create an Axios instance with the provided base URL
    this.api = axios.create();

    // Add a response interceptor to handle 401 errors
    this.api.interceptors.response.use(
      (response: any) => response,
      (error: AxiosError) => {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 401) {
            console.log(
              "Unauthorized request in catch block. Redirecting to login page."
            );
            const returnUrl = encodeURIComponent(
              location.pathname + location.search
            );
            history.push(`/login?returnUrl=${returnUrl}`);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getSecondaryListingsBySearchKeywordPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
            let url = `${requestHeaders.rooturl}${requests.fetchSecondaryListingsBySearchKeywordPaginated}?search_keyword=${searchKeyword}`;
            if (queryParams) {
                const queryString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join("&");
                url += `&${queryString}`;
            }
            const response = await this.api.get(url, { withCredentials: true });
            return response;
        } catch (error) {
            // Handle errors or return a default value as needed
            console.log("Printing error from getSecondaryListingsBySearchKeywordPaginated");
            console.error("Error:", error);
            throw error;
        }
    }

  async getSecondaryListingDetails(secondaryListingObject : SecondaryListings){
    try {
      let url = `${requestHeaders.rooturl}${requests.getSecondaryListingDetails}`;      
      const response = await this.api.post(url, secondaryListingObject, { withCredentials: true });
      return response;
    } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from getSecondaryListingDetails");
        console.error("Error:", error);
        throw error;
    }
  }


  async getAisListingsBySearchKeywordPaginated(
    searchKeyword: string,
    pageNumber: number,
    count: number
  ) {
        const queryParams: Record<string, any> = {
        page: pageNumber, // New page value
        count: count, // New count value
        };
        try {
            let url = `${requestHeaders.rooturl}${requests.fetchAisListingsBySearchKeywordPaginated}?search_keyword=${searchKeyword}`;
            if (queryParams) {
                const queryString = Object.keys(queryParams)
                .map((key) => `${key}=${queryParams[key]}`)
                .join("&");
                url += `&${queryString}`;
            }
            const response = await this.api.get(url, { withCredentials: true });
            return response;
        } catch (error) {
            // Handle errors or return a default value as needed
            console.log("Printing error from fetchAisListingsBySearchKeywordPaginated");
            console.error("Error:", error);
            throw error;
        }
    }

    async addOrUpdateAISlistingsInBulk(aisListings: AisListingUploadItem[], userId: string){
      try {
        let url = `${requestHeaders.rooturl}${requests.addOrUpdateAISlistingsInBulk}?user_id=${userId}`;       
        const response = await this.api.post(url, aisListings, { withCredentials: true });
        return response;
    } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from addOrUpdateAISlistingsInBulk");
        console.error("Error:", error);
        throw error;
    }
    }

    async addOrUpdatePacksAndCombosListingsInBulk(packsAndCombosListings: PacksCombosUploadItem[], userId: string){
      try {
          let url = `${requestHeaders.rooturl}${requests.addOrUpdatePacksComboslistingsInBulk}?user_id=${userId}`;       
          const response = await this.api.post(url, packsAndCombosListings, { withCredentials: true });
          return response;
      } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from addOrUpdatePacksAndCombosListingsInBulk");
          console.error("Error:", error);
          throw error;
      }
    }

    async addOrUpdateSecondaryListingsInBulk(secondaryListingUploadItems: SecondaryListingUploadItem[], userId: string){
      try {
        let url = `${requestHeaders.rooturl}${requests.addOrUpdateSecondarylistingsInBulk}?user_id=${userId}`;       
        const response = await this.api.post(url, secondaryListingUploadItems, { withCredentials: true });
        return response;
    } catch (error) {
        // Handle errors or return a default value as needed
        console.log("Printing error from addOrUpdateSecondaryListingsInBulk");
        console.error("Error:", error);
        throw error;
    }
    }


    async getPacksAndCombosBySearchKeywordPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {

      const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
          let url = `${requestHeaders.rooturl}${requests.fetchPacksAndCombosBySearchKeywordPaginated}?search_keyword=${searchKeyword}`;
          if (queryParams) {
              const queryString = Object.keys(queryParams)
              .map((key) => `${key}=${queryParams[key]}`)
              .join("&");
              url += `&${queryString}`;
          }
          const response = await this.api.get(url, { withCredentials: true });
          return response;
      } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getPacksAndCombosBySearchKeywordPaginated");
          console.error("Error:", error);
          throw error;
      }
    }

    async getPacksAndCombosListingDetails(pack_combo_sku_id : string){
      try {
        let url = `${requestHeaders.rooturl}${requests.fetchPacksAndCombosListingDetails}${pack_combo_sku_id}`;              
        const response = await this.api.get(url, { withCredentials: true });
        return response;
      } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getPacksAndCombosListingDetails");
          console.error("Error:", error);
          throw error;
      }
    }


    async getRawDownloadablePacksAndCombosPaginated(
      searchKeyword: string,
      pageNumber: number,
      count: number
    ) {

      const queryParams: Record<string, any> = {
      page: pageNumber, // New page value
      count: count, // New count value
      };
      try {
          let url = `${requestHeaders.rooturl}${requests.fetchDownloadablePacksAndCombosBySearchKeywordPaginated}?search_keyword=${searchKeyword}`;
          if (queryParams) {
              const queryString = Object.keys(queryParams)
              .map((key) => `${key}=${queryParams[key]}`)
              .join("&");
              url += `&${queryString}`;
          }
          const response = await this.api.get(url, { withCredentials: true });
          return response;
      } catch (error) {
          // Handle errors or return a default value as needed
          console.log("Printing error from getRawDownloadablePacksAndCombosPaginated");
          console.error("Error:", error);
          throw error;
      }
    }


}



export default LabelsNetworkUtils;
