import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import DateTimeUtils from "../../../utils/DateTimeUtils";
import { LabelData, LabelDataV2 } from "../../../atomic_components/types";
import { PDFDocument, rgb, degrees } from "pdf-lib";


class CompanyPurchaseOrderLabelUtils{
    static createPhase1BoxLabelsFor75by38(
        startingNumber: number,
        endingNumber: number,
        poNumber: string
      ) {
        const width = 7.5; // Longer side of the label (parallel to the text)
        const height = 3.8; // Shorter side of the label
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "landscape", // Set landscape for the desired layout
        });
      
        const mainFontSize = 25; // Font size for main text
        const dateFontSize = 6; // Font size for the date
        const margin = 0.3; // General margin
        const barcodeHeight = 1.5; // Barcode height reduced to 65% of label height
        const textStartY = barcodeHeight + 0.5; // Text starts just below the barcode
        const textStartX = margin; // Starting X position for text
        const lineHeight = 0.5; // Height between text lines
      
        for (let i = startingNumber; i <= endingNumber; i++) {
          if (i > startingNumber) doc.addPage(); // Add a new page for each label after the first one
      
          // Generate the barcode for the PO number
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, poNumber, { format: "CODE128", fontSize: 40});
          const barcodeImage = canvas.toDataURL("image/png");
      
          // Add the barcode spanning the entire width of the label
          doc.addImage(barcodeImage, "PNG", margin, margin, width - 2 * margin, barcodeHeight);
      
          // Add text content below the barcode
          let textY = textStartY;
      
          doc.setFontSize(mainFontSize);
      
          // Line 1: BOX NO.
          doc.text(`BOX NO. - ${i}`, textStartX, textY + 0.8);
          textY += lineHeight;
      
          // Line 2: PO Number
        //   doc.text(`PO Number: ${poNumber}`, textStartX, textY);
        //   textY += lineHeight;
      
          // Line 3: Current Date and Time
          doc.setFontSize(dateFontSize); // Use smaller font size for the date
          doc.text(`Date: ${DateTimeUtils.getCurrentDateTime()}`, textStartX, textY+1);
        }
      
        // Save the PDF with a descriptive name
        doc.save(`phase1_box_labels_${startingNumber}_to_${endingNumber}.pdf`);
    }

    static createPhase1BoxLabelsFor70by40(
        startingNumber: number,
        endingNumber: number,
        poNumber: string
      ) {
        console.log("inside 70 40")
        const width = 7.0; // Longer side of the label (parallel to the text)
        const height = 4.0; // Shorter side of the label
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "landscape", // Set landscape for the desired layout
        });
      
        const mainFontSize = 25; // Font size for main text
        const dateFontSize = 6; 
        const margin = 0.4; // General margin
        const barcodeHeight = 1.5; // Adjusted barcode height to 65% of the label height
        const textStartY = barcodeHeight + 0.6; // Text starts just below the barcode
        const textStartX = margin; // Starting X position for text
        const lineHeight = 0.5; // Height between text lines
      
        for (let i = startingNumber; i <= endingNumber; i++) {
          if (i > startingNumber) doc.addPage(); // Add a new page for each label after the first one
      
          // Generate the barcode for the PO number
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, poNumber, { format: "CODE128", fontSize: 40});
          const barcodeImage = canvas.toDataURL("image/png");
      
          // Add the barcode spanning the entire width of the label
          doc.addImage(
            barcodeImage,
            "PNG",
            margin, // X position (aligned with margin)
            margin, // Y position (top margin)
            width - 2 * margin, // Full width minus margins
            barcodeHeight // Adjusted height
          );
      
          // Add text content below the barcode
          let textY = textStartY;
      
          doc.setFontSize(mainFontSize);
      
          // Line 1: BOX NO.
          doc.text(`BOX NO. - ${i}`, textStartX, textY + 0.8);
          textY += lineHeight;
      
          // Line 2: PO Number
          
      
          // Line 3: Current Date and Time
          doc.setFontSize(dateFontSize); // Use smaller font size for the date
          doc.text(`Date: ${DateTimeUtils.getCurrentDateTime()}`, textStartX, textY+1);
        }
      
        // Save the PDF with a descriptive name
        doc.save(`phase1_box_labels_${startingNumber}_to_${endingNumber}.pdf`);
      }
      
      
    static generateMyntraLabels75By38_v1 = async (        
        labelData: LabelData,
        labelHeadings: LabelData,
        labelCount: number
      ) => {

        const width = 3.8;
        const height = 7.5;
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Further reduced font size for tighter fit
        doc.setFontSize(fontSize);
    
        // General margin and content settings
        const margin = 0.15; // Further reduced border margin
        const contentWidth = width - 2 * margin; // Available width within the border
        const textMargin = margin + 0.1; // Reduced margin between text elements
        const barcodeHeight = height / 7; // Adjusted barcode height for smaller label
        const cellHeight3Lines = 0.7; // Further reduced cell height to fit more content
        const cellHeight1Line = 0.3;
    
        for (let i = 0; i < labelCount; i++) {
          if (i > 0) doc.addPage(); // Add a new page for each label after the first one
    
          // Generate barcode
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, labelData.barcode, { format: "CODE128" });
          const barcodeImage = canvas.toDataURL("image/png");
    
          // Add barcode image at the top (centered within the border)
          const barcodeWidth = contentWidth - 0.8; // Adjust width for alignment
          const barcodeWidthNew = contentWidth - 0.8;
          const barcodeX = (width - barcodeWidthNew) / 2; // Center barcode
          doc.addImage(
            barcodeImage,
            "PNG",
            barcodeX,
            margin,
            barcodeWidthNew,
            barcodeHeight
          );
    
          // Draw border around the entire label
          // doc.setLineWidth(0.05); // Reduced border thickness
          // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
    
          // Add table data below the barcode
          const contentStartY = margin + barcodeHeight + 0.3; // Start just below the barcode
          const keys = Object.keys(labelData) as (keyof LabelData)[];
          const filteredKeys = keys.filter((key) => key !== "barcode");
    
          let y = contentStartY;
          filteredKeys.forEach((key, index) => {
            // Combine heading and value into a single wrapped text
            const fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
    
            // Add wrapped text within the border
            doc.text(fullText, textMargin, y, {
              maxWidth: contentWidth - margin, // Ensure text wraps within the border
            });
    
            if (
              key === "marketedBy" ||
              key === "manufacturedBy" ||
              key === "oneSetContains"
            ) {
              y += cellHeight3Lines;
            } else {
              y += cellHeight1Line;
            }
          });
        }
    
        // Save the PDF as a Uint8Array
        doc.save("labels.pdf");
        // const pdfBytes = doc.output("arraybuffer");
    
        // // Rotate the pages using PDF-Lib
        // await rotatePDFPages(pdfBytes);
      };
    

    ///IMPORTANT, rotated barcode.
    static ellipsize = (text: string, maxLength: number): string => {
        return text.length > maxLength
          ? text.substring(0, maxLength - 3) + "..."
          : text;
      };
    
    static generateMyntraLabels75By38_v2 = async (
        labelData: LabelData,
        labelHeadings: LabelData,
        labelCount: number
      ) => {
        const width = 3.8; // Total label width in cm
        const height = 7.5; // Total label height in cm
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
    
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
    
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
    
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
    
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
    
        for (let i = 0; i < labelCount; i++) {
          if (i > 0) doc.addPage(); // Add a new page for each label after the first
    
          // Generate the barcode image without the text
          const canvas = document.createElement("canvas");
          JsBarcode(canvas, labelData.barcode, {
            format: "CODE128",
            displayValue: true, // Ensure that no text is shown with the barcode
            fontSize: 24,
          });
          const barcodeImage = canvas.toDataURL("image/png");
    
          // Add the barcode image in the left section
          doc.addImage(
            barcodeImage,
            "PNG",
            barcodeX,
            -0.5, // Y position (top margin)
            barcodeWidthFinal,
            barcodeHeight,
            undefined,
            "FAST",
            -90 // Rotate the barcode to be aligned on the left
          );
    
          // Add text in the right part
          const keys = Object.keys(labelData) as (keyof LabelData)[];
          const filteredKeys = keys.filter((key) => key !== "barcode");
    
          let y = textStartY;
          filteredKeys.forEach((key) => {
            let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
            if (key === "title") {
              fullText = this.ellipsize(fullText, 128);
            } else if (key === "oneSetContains") {
              fullText = this.ellipsize(fullText, 52);
            }
    
            // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
            if (
              key === "marketedBy" ||
              key === "manufacturedBy" ||
              key === "title"
            ) {
              doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
            } else {
              doc.setFontSize(fontSize); // Restore default font size
            }
    
            // Add the text to the PDF
            doc.text(fullText, textStartX, y, {
              maxWidth: textContentWidth,
            });
    
            // Adjust the y position based on content type
            if (key === "marketedBy") {
              y += cellHeight6Lines;
            } else if (key === "manufacturedBy") {
              y += cellHeight5Lines;
            } else if (key === "title") {
              y += cellHeight4Lines;
            } else if (
              key === "productDimension" ||
              key === "modelNumber" ||
              key === "oneSetContains" ||
              key === "mrp" ||
              key === "styleId"
            ) {
              y += cellHeight2Line;
            } else {
              y += cellHeight1Line;
            }
          });
        }
    
        // Save the PDF
        // doc.save("labels_with_barcode_only_on_left.pdf");

        const pdfBytes = doc.output("arraybuffer");
    
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
      };

    
    static generateMyntraLabels70By40_v2 = async (
      labelData: LabelData,
      labelHeadings: LabelData,
      labelCount: number
    ) => {
      const width = 4.0; // Total label width in cm
      const height = 7.0; // Total label height in cm
      const doc = new jsPDF({
        unit: "cm",
        format: [width, height],
        orientation: "portrait",
      });
  
      const fontSize = 5; // Default font size for the text
      doc.setFontSize(fontSize);
  
      // Dimensions for the two parts
      const barcodePartWidth = 1; // Left part width for barcode
      const textWidth = width - barcodePartWidth; // Right part width for text
      const margin = 0.3; // General margin for content
  
      // Barcode dimensions (swap width and height due to rotation)
      const barcodeHeight = 1; // Height corresponds to the width
      const barcodeWidthFinal = 5.0; // Width corresponds to the height
      const barcodeX = margin; // Left margin for barcode
      const barcodeY = margin; // Top margin for the barcode
  
      // Text area start positions
      const textStartX = barcodePartWidth + margin; // Start text area after the barcode
      const textStartY = margin; // Start text from the top margin
      const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
  
      const cellHeight6Lines = 1.05; // Height for multi-line content
      const cellHeight5Lines = 0.9; // Height for multi-line content
      const cellHeight4Lines = 0.8; // Height for multi-line content
      const cellHeight1Line = 0.3; // Height for single-line content
      const cellHeight2Line = 0.5; // Height for single-line content
  
      for (let i = 0; i < labelCount; i++) {
        if (i > 0) doc.addPage(); // Add a new page for each label after the first
  
        // Generate the barcode image without the text
        const canvas = document.createElement("canvas");
        JsBarcode(canvas, labelData.barcode, {
          format: "CODE128",
          displayValue: true, // Ensure that no text is shown with the barcode
          fontSize: 24,
        });
        const barcodeImage = canvas.toDataURL("image/png");
  
        // Add the barcode image in the left section
        doc.addImage(
          barcodeImage,
          "PNG",
          barcodeX,
          -0.5, // Y position (top margin)
          barcodeWidthFinal,
          barcodeHeight,
          undefined,
          "FAST",
          -90 // Rotate the barcode to be aligned on the left
        );
  
        // Add text in the right part
        const keys = Object.keys(labelData) as (keyof LabelData)[];
        const filteredKeys = keys.filter((key) => key !== "barcode");
  
        let y = textStartY;
        filteredKeys.forEach((key) => {
          let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
          if (key === "title") {
            fullText = this.ellipsize(fullText, 128);
          } else if (key === "oneSetContains") {
            fullText = this.ellipsize(fullText, 52);
          }
  
          // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
          if (
            key === "marketedBy" ||
            key === "manufacturedBy" ||
            key === "title"
          ) {
            doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
          } else {
            doc.setFontSize(fontSize); // Restore default font size
          }
  
          // Add the text to the PDF
          doc.text(fullText, textStartX, y, {
            maxWidth: textContentWidth,
          });
  
          // Adjust the y position based on content type
          if (key === "marketedBy") {
            y += cellHeight6Lines;
          } else if (key === "manufacturedBy") {
            y += cellHeight5Lines;
          } else if (key === "title") {
            y += cellHeight4Lines;
          } else if (
            key === "productDimension" ||
            key === "modelNumber" ||
            key === "oneSetContains" ||              
            key === "styleId"
          ) {
            y += cellHeight2Line;
          } else {
            y += cellHeight1Line;
          }
        });
      }
  
      // Save the PDF
      // doc.save("labels_with_barcode_only_on_left.pdf");

      const pdfBytes = doc.output("arraybuffer");
  
      // Rotate the pages using PDF-Lib
      await this.rotatePDFPages(pdfBytes);
    };

    private static rotatePDFPages = async (pdfBytes: ArrayBuffer) => {
        // Load the PDF into PDF-Lib
        const pdfDoc = await PDFDocument.load(pdfBytes);
    
        // Rotate each page
        const pages = pdfDoc.getPages();
        pages.forEach((page) => {
          page.setRotation(degrees(90)); // Rotate 90 degrees clockwise
        });
    
        // Save the rotated PDF
        const rotatedPdfBytes = await pdfDoc.save();
    
        // Download the rotated PDF
        const blob = new Blob([rotatedPdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
    
        const link = document.createElement("a");
        link.href = url;
        link.download = "rotated_labels.pdf";
        link.click();
    
        URL.revokeObjectURL(url);
      };


    static generateDmartLabels70By40_v2 = async (
        labelDataArray: LabelDataV2[],
        labelHeadings: LabelData,        
      ) => {
        const width = 4.0; // Total label width in cm
        const height = 7.0; // Total label height in cm
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
    
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
    
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
    
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
    
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
    
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
        
        const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
        console.log(keys);
        const filteredKeys = keys.filter((key) => (key !== "barcode" && key !== "styleId") );
        console.log(filteredKeys);

        for(let j=0;j<labelDataArray.length;j++){          
          let labelData = labelDataArray[j];                    
          for (let i = 0; i < labelData.labelCount; i++) {            
            if (j+i > 0) doc.addPage(); // Add a new page for each label after the first
      
            // Generate the barcode image without the text
            const canvas = document.createElement("canvas");
            JsBarcode(canvas, labelData.barcode, {
              format: "CODE128",
              displayValue: true, // Ensure that no text is shown with the barcode
              fontSize: 24,
            });
            const barcodeImage = canvas.toDataURL("image/png");
      
            // Add the barcode image in the left section
            doc.addImage(
              barcodeImage,
              "PNG",
              barcodeX,
              -0.5, // Y position (top margin)
              barcodeWidthFinal,
              barcodeHeight,
              undefined,
              "FAST",
              -90 // Rotate the barcode to be aligned on the left
            );
      
            // Add text in the right part
           
      
            let y = textStartY;
            filteredKeys.forEach((key) => {
              let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
              if (key === "title") {
                fullText = this.ellipsize(fullText, 128);
              } else if (key === "oneSetContains" || key  === "productDimension") {
                fullText = this.ellipsize(fullText, 52);
              }
      
              // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
              if (
                key === "marketedBy" ||
                key === "manufacturedBy" ||
                key === "title"
              ) {
                doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
              } else {
                doc.setFontSize(fontSize); // Restore default font size
              }
      
              // Add the text to the PDF
              doc.text(fullText, textStartX, y, {
                maxWidth: textContentWidth,
              });
      
              // Adjust the y position based on content type
              if (key === "marketedBy") {
                y += cellHeight6Lines;
              } else if (key === "manufacturedBy") {
                y += cellHeight5Lines;
              } else if (key === "title") {
                y += cellHeight4Lines;
              } else if (
                key === "productDimension" ||
                key === "modelNumber" ||
                key === "oneSetContains" ||              
                key === "styleId"
              ) {
                y += cellHeight2Line;
              } else {
                y += cellHeight1Line;
              }
            });
          }
        }
    
        
    
        // Save the PDF
        // doc.save("labels_with_barcode_only_on_left.pdf");
  
        const pdfBytes = doc.output("arraybuffer");
    
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
      };

    static generateDmartLabels70By40_v3 = async (
        labelDataArray: LabelDataV2[],
        labelHeadings: LabelData,
      ) => {
        
        const width = 4.0; // Total label width in cm
        const height = 7.0; // Total label height in cm

        
      
        const doc = new jsPDF({
          unit: "cm",
          format: [width, height],
          orientation: "portrait",
        });
      
        const fontSize = 5; // Default font size for the text
        doc.setFontSize(fontSize);
      
        // Dimensions for the two parts
        const barcodePartWidth = 1; // Left part width for barcode
        const textWidth = width - barcodePartWidth; // Right part width for text
        const margin = 0.3; // General margin for content
      
        // Barcode dimensions (swap width and height due to rotation)
        const barcodeHeight = 1; // Height corresponds to the width
        const barcodeWidthFinal = 5.0; // Width corresponds to the height
        const barcodeX = margin; // Left margin for barcode
        const barcodeY = margin; // Top margin for the barcode
      
        // Text area start positions
        const textStartX = barcodePartWidth + margin; // Start text area after the barcode
        const textStartY = margin; // Start text from the top margin
        const textContentWidth = textWidth - 2 * margin; // Ensure text fits within its part
      
        const cellHeight6Lines = 1.05; // Height for multi-line content
        const cellHeight5Lines = 0.9; // Height for multi-line content
        const cellHeight4Lines = 0.8; // Height for multi-line content
        const cellHeight1Line = 0.3; // Height for single-line content
        const cellHeight2Line = 0.5; // Height for single-line content
      
        const keys = Object.keys(labelHeadings) as (keyof LabelData)[];
        const filteredKeys = keys.filter((key) => key !== "barcode" && key !== "styleId");
      
        for (let j = 0; j < labelDataArray.length; j++) {
          let labelData = labelDataArray[j];
          for (let i = 0; i < labelData.labelCount; i++) {
            if (j + i > 0) doc.addPage(); // Add a new page for each label after the first
      
            // Generate the barcode image without the text
            const canvas = document.createElement("canvas");
            JsBarcode(canvas, labelData.barcode, {
              format: "CODE128",
              displayValue: true, // Ensure that no text is shown with the barcode
              fontSize: 24,
            });
            const barcodeImage = canvas.toDataURL("image/png");
      
            // Add the barcode image in the left section
            doc.addImage(
              barcodeImage,
              "PNG",
              barcodeX,
              -0.5, // Y position (top margin)
              barcodeWidthFinal,
              barcodeHeight,
              undefined,
              "FAST",
              -90 // Rotate the barcode to be aligned on the left
            );
      
            // Add text in the right part
            let y = textStartY;
            filteredKeys.forEach((key) => {
              let fullText = `${labelHeadings[key]}: ${labelData[key] || ""}`;
              if (key === "mrp") {
                fullText = `${labelHeadings[key]}: ${labelData[key] || ""}.00`; // Add Rupee symbol for MRP
              } else if (key === "title") {
                fullText = this.ellipsize(fullText, 128);
              } else if (key === "oneSetContains" || key === "productDimension") {
                fullText = this.ellipsize(fullText, 52);
              }
      
              // Check if the key is "marketedBy" or "manufacturedBy" and set font size to 4
              if (
                key === "marketedBy" ||
                key === "manufacturedBy" ||
                key === "title"
              ) {
                doc.setFontSize(4); // Set smaller font size for "marketedBy" and "manufacturedBy"
              } else {
                doc.setFontSize(fontSize); // Restore default font size
              }
      
              // Add the text to the PDF
              doc.text(fullText, textStartX, y, {
                maxWidth: textContentWidth,
              });
      
              // Adjust the y position based on content type
              if (key === "marketedBy") {
                y += cellHeight6Lines;
              } else if (key === "manufacturedBy") {
                y += cellHeight5Lines;
              } else if (key === "title") {
                y += cellHeight4Lines;
              } else if (
                key === "productDimension" ||
                key === "modelNumber" ||
                key === "oneSetContains" ||
                key === "styleId"
              ) {
                y += cellHeight2Line;
              } else {
                y += cellHeight1Line;
              }
            });
          }
      
          doc.addPage();
          doc.setFontSize(30); // Larger font size for better visibility
          doc.setTextColor(255, 0, 0); // Set text color to red for high visibility
          
          // Draw a rectangle as a border for the separator
          doc.setDrawColor(255, 0, 0); // Red border
          // doc.setLineWidth(0.1); // Border thickness
          // doc.rect(margin, margin, width - 2 * margin, height - 2 * margin);
          
          // Add separator text
          doc.text("*BREAK*", width / 2, height / 2 + 0.5, {
            align: "center",
          });
          doc.setFontSize(10);          
          doc.setTextColor(0, 0, 0); // Reset text color to black
        }
      
        // Save the PDF
        const pdfBytes = doc.output("arraybuffer");
      
        // Rotate the pages using PDF-Lib
        await this.rotatePDFPages(pdfBytes);
      };
      
}

export default CompanyPurchaseOrderLabelUtils;