import React, { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./CSS/CompanyPurchaseOrderAddItems.css";
import {
  Alert,
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Modal,
  ModalProps,
  Offcanvas,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";

import {
  Catalogue,
  CatalogueItem,
  ErrorMessageBody,
  IncomingPurchaseOrder,
  IncomingPurchaseOrderItemDetails,
  IncomingPurchaseOrderItemDetailsHasError,
  IncomingPurchaseOrderItemDetailsHasErrorMessages,
  Listing,
  PoItem,
  PurchaseOrder,
  SearchParams,
  SkuValidationAndExtractionObject,
  SkuValidationObject,
  SkuValidationResponseDataObject,
  SkuValidationResponseObject,
  Specialty,
  Vendor,
  VendorListing,
} from "../../atomic_components/types";
import ListingsView from "../ListingsAndInventory/ListingsView";

import _ from "lodash";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useAppSelector } from "../../state/hooks";
import ListingNetworkUtil from "../ListingsAndInventory/NetworkUtils/ListingNetworkUtils";

import { Prev } from "react-bootstrap/esm/PageItem";
import DateTimeUtils from "../../utils/DateTimeUtils";
import VendorsNetworkUtil from "../Vendors/NetworkUtils/VendorsNetworkUtil";
import Header from "../Header";
import VendorListingsView from "../Vendors/VendorListingsView";
import VendorsListingSearchBar from "../Vendors/VendorsListingSearchBar";
import { Omit, BsPrefixProps } from "react-bootstrap/esm/helpers";
// import PurchaseOrdersVendorListingsView from "./PurchaseOrdersVendorListingsView";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";
import { green } from "@mui/material/colors";

import { Gif } from "@mui/icons-material";
import Papa from "papaparse";
import jsonexport from "jsonexport";
import CompanyPurchaseOrderNetworkUtils from "./NetworkUtils/CompnayPurchaseOrderNetworkUtils";

interface RouteParams {
  po_id: string;
}

function CompanyPurchaseOrdersAddItems() {
  const userDetailsFromStore = useAppSelector(
    (state) => state.userdetails_store
  );

  const history = useHistory();
  const location = useLocation();
  const { po_id } = useParams<RouteParams>();

  const companyPurchaseOrderNetworkUtil = new CompanyPurchaseOrderNetworkUtils(
    "https://your-api-endpoint.com",
    history,
    location
  );

  const [errorCount1, setErrorCount1] = useState<number>(0);
  const [errorCount2, setErrorCount2] = useState<number>(0);
  const [currentSkuValidationResponseObject, setSkuValidationResponseObject] =
    useState<SkuValidationResponseObject>();
  const [showLoadingPage, setShowLoadingPage] = useState<boolean>(true);

  const [finalData, setFinalData] = useState<
    IncomingPurchaseOrderItemDetails[]
  >([]);
  const [finalDataHasError, setFinalDataHasError] = useState<
    IncomingPurchaseOrderItemDetailsHasError[]
  >([]);
  const [finalDataMessages, setFinalDataMessages] = useState<
    IncomingPurchaseOrderItemDetailsHasErrorMessages[]
  >([]);

  const [currentPurchaseOrder, setCurrentPurchaseOrder] =
    useState<IncomingPurchaseOrder>();
  const [uploadButtonActive, setUploadButtonActive] = useState<boolean>(false);
  const [uploadButtonClicked, setUploadButtonClicked] =
    useState<boolean>(false);

  const [deletePurchaseOrder, setDeletePurchaseOrder] = useState(false);

  useEffect(() => {
    const fetchPurchaseOrderDetails = async (purchase_order_id: any) => {
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.geturchaseOrderByPOid(
            purchase_order_id.toString()
          );
        // console.log(response.data);
        setCurrentPurchaseOrder(response.data);
        setShowLoadingPage(false);
      } catch (error) {
        history.push("/incomingPos/partiallyCreatedPos");
        toast.error("Error! Could not find purchase order details.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    };

    fetchPurchaseOrderDetails(po_id);
  }, []);

  const mapToIncomingPurchaseOrderItemDetails = async (data: any[]) => {
    const incomingPurchaseOrderItemDetails: IncomingPurchaseOrderItemDetails[] =
      [];
    const incomingPurchaseOrderItemDetailsHasErrorMessages: IncomingPurchaseOrderItemDetailsHasErrorMessages[] =
      [];
    const incomingPurchaseOrderItemDetailsHasError: IncomingPurchaseOrderItemDetailsHasError[] =
      [];
    const skuValidationObjectList: SkuValidationAndExtractionObject[] = [];

    let errorCount = 0;

    data.forEach((item) => {
      const errorMessage: IncomingPurchaseOrderItemDetailsHasErrorMessages = {
        po_secondary_sku: "",
        master_sku: "",
        inventory_sku_id: "",
        pack_combo_sku_id: "",
        sku_type: "",
        company_code_primary: "",
        company_code_secondary: "",
        demand: "",
        hsn_code: "",
        title: "",
        mrp: "",
        nlc_tp: "",
        tax_rate: "",
        size: "",
        color: "",
      };

      const booleanError: IncomingPurchaseOrderItemDetailsHasError = {
        po_secondary_sku: false,
        master_sku: false,
        inventory_sku_id: false,
        pack_combo_sku_id: false,
        sku_type: false,
        company_code_primary: false,
        company_code_secondary: false,
        demand: false,
        hsn_code: false,
        title: false,
        mrp: false,
        nlc_tp: false,
        tax_rate: false,
        size: false,
        color: false,
      };

      const po_secondary_sku = item.po_secondary_sku;
      if (!item.po_secondary_sku) {
        errorMessage.po_secondary_sku = "po_secondary_sku is missing";
        booleanError.po_secondary_sku = true;
        errorCount++;
      }

      const company_code_primary = item.company_code_primary;
      if (!item.company_code_primary) {
        errorMessage.company_code_primary = "company_code_primary is missing";
        booleanError.company_code_primary = true;
        errorCount++;
      }

      const company_code_secondary = item.company_code_secondary;

      const demand = item.demand;
      if (isNaN(Number(item.demand)) || demand == 0) {
        errorMessage.demand = "No. > 0";
        booleanError.demand = true;
        errorCount++;
      }

      const hsn_code = item.hsn_code || ""; // Optional field, no error needed

      const title = item.title;
      if (!item.title) {
        errorMessage.title = "title is missing";
        booleanError.title = true;
        errorCount++;
      }

      const mrp = item.mrp;
      if (isNaN(Number(item.mrp))) {
        errorMessage.mrp = "mrp must be a number";
        booleanError.mrp = true;
        errorCount++;
      }

      const nlc_tp = item.nlc_tp;
      if (isNaN(Number(item.nlc_tp))) {
        errorMessage.nlc_tp = "nlc_tp must be a number";
        booleanError.nlc_tp = true;
        errorCount++;
      }

      const tax_rate = item.tax_rate;
      if (isNaN(Number(item.tax_rate))) {
        errorMessage.tax_rate = "tax_rate must be a number";
        booleanError.tax_rate = true;
        errorCount++;
      } else if (Number(item.tax_rate) == 0) {
        errorMessage.tax_rate = "tax_rate must not be 0";
        booleanError.tax_rate = true;
        errorCount++;
      } else if (
        Number(item.tax_rate) != 3 &&
        Number(item.tax_rate) != 5 &&
        Number(item.tax_rate) != 12 &&
        Number(item.tax_rate) != 18 &&
        Number(item.tax_rate) != 28
      ) {
        errorMessage.tax_rate =
          "invalid tax_rate, it must be either 3, 5, 12, 18, 28";
        booleanError.tax_rate = true;
        errorCount++;
      }

      const size = item.size || ""; // Optional field, no error needed
      const color = item.color || ""; // Optional field, no error needed

      skuValidationObjectList.push({
        po_secondary_sku: po_secondary_sku,
        title: title,
      });

      incomingPurchaseOrderItemDetailsHasError.push(booleanError);
      incomingPurchaseOrderItemDetailsHasErrorMessages.push(errorMessage);
      incomingPurchaseOrderItemDetails.push({
        po_secondary_sku: po_secondary_sku,
        master_sku: "",
        inventory_sku_id: "",
        pack_combo_sku_id: "",
        sku_type: "",
        company_code_primary: company_code_primary,
        company_code_secondary: company_code_secondary,
        demand: demand, // Ensuring the demand is a number
        hsn_code: hsn_code,
        title: title,
        mrp: mrp, // Ensuring the MRP is a number
        nlc_tp: nlc_tp, // Ensuring NLC/TP is a number
        tax_rate: tax_rate, // Ensuring tax_rate is a number
        size: size,
        color: color,
      });
    });

    if (errorCount == 0) {
      //level 2 check
      try {
        const response =
          await companyPurchaseOrderNetworkUtil.getMasterAndOtherSkuMappingForPurchaseOrder(
            skuValidationObjectList
          );
        const obj: SkuValidationResponseObject = response.data;
        setSkuValidationResponseObject(response.data);

        if (obj.data_object) {
          incomingPurchaseOrderItemDetails.forEach((item, index) => {
            item.master_sku = obj.data_object[index].master_sku;
            item.inventory_sku_id = obj.data_object[index].inventory_sku_id;
            item.pack_combo_sku_id = obj.data_object[index].pack_combo_sku_id;
            item.sku_type = obj.data_object[index].sku_type;
          });
        }
        console.log(incomingPurchaseOrderItemDetails);
        setErrorCount2(obj.error_count);
      } catch (error) {
        console.error("Error:", error);
        const err = error as AxiosError;
        toast.error("Error! Upload unsuccessful.", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }

    setFinalDataMessages(incomingPurchaseOrderItemDetailsHasErrorMessages);
    setFinalDataHasError(incomingPurchaseOrderItemDetailsHasError);
    setFinalData(incomingPurchaseOrderItemDetails);
    setErrorCount1(errorCount);
  };

  const handleSampleDownload = async () => {
    try {
      jsonexport(
        [
          {
            po_secondary_sku:
              "ecraft's secondary_sku/child_sku that will go on label Eg. AGB506_PO6_AR",
            company_code_primary:
              "EAN, FSNor other code that makes the barcode",
            company_code_secondary: "Style IDs, Internal EAN etc.",
            demand: "Quantity required by company",
            hsn_code: "Product HSN code",
            title: "Product Title",
            mrp: "Product MRP",
            nlc_tp: "Product's price exclusive of taxes",
            tax_rate: "Product's applicable tax rate",
            size: "Product's size",
            color: "Product's color",
          },
        ],
        (err: Error | null, csv: string) => {
          if (err) {
            console.error(err);
            return;
          }
          // Create a Blob object with the CSV data
          const blob = new Blob([csv], { type: "text/csv" });
          // Create a link element to trigger the download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `eCraftZap-${po_id}.csv`;
          link.click();
        }
      );
    } catch (error) {
      console.log("Printing error from handleSampleDownload");
      console.error("Error:", error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const expectedHeaders = [
      "po_secondary_sku",
      "company_code_primary",
      "company_code_secondary",
      "demand",
      "hsn_code",
      "title",
      "mrp",
      "nlc_tp",
      "tax_rate",
      "size",
      "color",
    ];
    setUploadButtonActive(false);

    if (event.target.files) {
      const selectedFile = event.target.files[0];
      // let jsonPayload: any[] | null = null;
      if (selectedFile) {
        Papa.parse(selectedFile, {
          complete: (result) => {
            const fileHeaders = result.meta.fields;
            if (fileHeaders) {
              const headersMatch =
                expectedHeaders.every((header) =>
                  fileHeaders.includes(header)
                ) &&
                fileHeaders.every((header) => expectedHeaders.includes(header));

              if (!headersMatch) {
                toast.error(
                  "Error: The CSV file headers are incorrect. Please upload a valid file.",
                  {
                    position: toast.POSITION.TOP_RIGHT,
                    hideProgressBar: true,
                  }
                );
                return; // Stop further processing
              }

              // If headers match, process the data
              setUploadButtonActive(true);
              mapToIncomingPurchaseOrderItemDetails(result.data);
              console.log(result.data);
            } else {
              toast.error(
                "Error: The CSV file headers are missing. Please upload a valid file.",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  hideProgressBar: true,
                }
              );
            }
          },
          header: true, // Treat the first row as header
        });
      }

      // console.log(jsonPayload);
      // setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    setUploadButtonClicked(true);
  };

  const deleteThisPurchaseOrder = async () => {
    try {
      const response =
        await companyPurchaseOrderNetworkUtil.deletePartialPurchaseOrder(po_id);

      toast.success(`PO : ${po_id} deleted successfully.`, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
      history.push("/incomingPos/partiallyCreatedPos");
    } catch (error) {
      console.error("Error in deleting partial purchase order", error);
      const err = error as AxiosError;
      if (err.response && err.response.data && err.response.status == 400) {
        console.log((err.response.data as ErrorMessageBody).message);
        toast.error((err.response.data as ErrorMessageBody).message, {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      } else {
        toast.error("Unexpected error", {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        });
      }
    }
  };

  const handlePoSubmission = async () => {
    if (errorCount1 > 0 || errorCount2 > 0) {
      toast.error(
        `Clear all errors before uploading. Error remaining : ${
          errorCount1 + errorCount2
        }`,
        {
          position: toast.POSITION.TOP_RIGHT,
          hideProgressBar: true,
        }
      );
      return;
    }

    try {
      const response =
        await companyPurchaseOrderNetworkUtil.submitPurchaseOrderData(
          finalData,
          po_id,
          userDetailsFromStore.user_details.userId
        );

      toast.success("Upload successful", {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });

      history.push(`/incomingPos/info/details/${po_id}`);
    } catch (error) {
      console.error("Error:", error);
      const err = error as AxiosError;
      if (err.response) {
        if (err.response.data && err.response.status == 400) {
          toast.error((err.response.data as ErrorMessageBody).message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        } else {
          toast.error("Error! Upload unsuccessful.", {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
          });
        }
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className="overlay">
      <Header />
      <div className="upload-purchase-order-details-container">
        {currentPurchaseOrder && (
          <div className="incoming-purchase-order-details-table-container pt-4">
            <Button
              variant="outline-primary"
              className="vendor-action-button"
              onClick={() => setDeletePurchaseOrder(true)}
            >
              Delete this partial purchase order
            </Button>
            <Table
              striped
              bordered
              hover
              size="sm"
              className="table-auto-width"
            >
              <tbody>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Buyer Company Name</td>
                  <td>{currentPurchaseOrder.company.company_name}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">PO Number</td>
                  <td>{currentPurchaseOrder.po_number}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Reference Location</td>
                  <td>{currentPurchaseOrder.delivery_city}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Delivery Address</td>
                  <td>{currentPurchaseOrder.delivery_address}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Billing Address</td>
                  <td>{currentPurchaseOrder.billing_address}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Buyer GSTIN</td>
                  <td>{currentPurchaseOrder.buyer_gstin}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Expiry Date</td>
                  <td>
                    {DateTimeUtils.formatDateOnly(
                      currentPurchaseOrder.expiry_date
                    )}
                  </td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Created by</td>
                  <td>{currentPurchaseOrder.created_by}</td>
                </tr>
                <tr className="catalogues-search-bar-row">
                  <td className="nowrap">Created At</td>
                  <td>
                    {DateTimeUtils.formatDateTime(
                      currentPurchaseOrder.created_at
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        )}

        {showLoadingPage ? (
          <div className="loading-page">
            <Spinner animation="grow" />
            Loading, please wait...
          </div>
        ) : !uploadButtonClicked ? (
          <div className="upload-incoming-po-container">
            <div className="upload-incoming-po-container-insider mb-4 pt-4 pb-4">
              <div className="upload-snapshot-container">
                <div className="upload-snapshot-heading-text">
                  Upload recieved PO, in the provided format
                </div>
                <div>
                  <p
                    className="download-sample-link"
                    onClick={handleSampleDownload}
                  >
                    [Download Sample File]
                  </p>
                </div>
                <div className="upload-controls">
                  <input
                    className="upload-snapshot-button"
                    type="file"
                    onChange={handleFileChange}
                    accept=".csv"
                    id="fileInput"
                  />

                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => {
                      handleUpload();
                    }}
                    disabled={!uploadButtonActive}
                    className={
                      !uploadButtonActive
                        ? "upload-button disabled-hover"
                        : "upload-button"
                    }
                    style={{
                      cursor: !uploadButtonActive ? "not-allowed" : "pointer",
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="po-items-container">
            {errorCount1 > 0 ? (
              <div className="error-count-container pb-2">
                Error check number 1 failed with : {errorCount1}{" "}
                {errorCount1 > 1 ? " errors," : " error."}
              </div>
            ) : (
              <div className="success-container pb-2">
                Error check number 1 passed with 0 errors.
              </div>
            )}

            {errorCount1 == 0 &&
              (errorCount2 > 0 ? (
                <div className="error-count-container pb-2">
                  Error check number 2 failed with : {errorCount2}{" "}
                  {errorCount2 > 1 ? " errors," : " error."}
                </div>
              ) : (
                <div className="success-container pb-2">
                  Error check number 2 passed with 0 errors.
                </div>
              ))}

            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>sr_no.</th>
                  <th>po_secondary_sku</th>
                  {errorCount1 == 0 && currentSkuValidationResponseObject && (
                    <th>master_sku</th>
                  )}
                  {errorCount1 == 0 && currentSkuValidationResponseObject && (
                    <th>inventory_sku_id</th>
                  )}
                  {errorCount1 == 0 && currentSkuValidationResponseObject && (
                    <th>pack_combo_sku_id</th>
                  )}
                  {errorCount1 == 0 && currentSkuValidationResponseObject && (
                    <th>sku_type</th>
                  )}
                  <th>company_code_primary</th>
                  <th>company_code_secondary</th>
                  <th>demand</th>
                  <th>hsn_code</th>
                  <th>title</th>
                  <th>mrp</th>
                  <th>nlc_tp</th>
                  <th>tax_rate</th>
                  <th>size</th>
                  <th>color</th>
                </tr>
              </thead>
              <tbody>
                {finalData.map((purchaseOrderItem, index) => {
                  const errorMessages = finalDataMessages[index];
                  const errors = finalDataHasError[index];

                  // Helper functions for class and tooltip generation
                  const getClassForError = (error: boolean) =>
                    error ? "error-cell" : "";

                  const getTooltipForError = (
                    error: boolean,
                    errorMessage: string
                  ) =>
                    error ? (
                      <span className="tooltip">{errorMessage}</span>
                    ) : null;

                  return (
                    <tr className="catalogues-search-bar-row" key={index}>
                      <td>{index + 1}</td>
                      <td className={getClassForError(errors.po_secondary_sku)}>
                        {getTooltipForError(
                          errors.po_secondary_sku,
                          errorMessages.po_secondary_sku
                        )}
                        {purchaseOrderItem.po_secondary_sku}
                      </td>

                      {errorCount1 == 0 &&
                        currentSkuValidationResponseObject && (
                          <td>{purchaseOrderItem.master_sku}</td>
                        )}
                      {errorCount1 == 0 &&
                        currentSkuValidationResponseObject && (
                          <td>{purchaseOrderItem.inventory_sku_id}</td>
                        )}
                      {errorCount1 == 0 &&
                        currentSkuValidationResponseObject && (
                          <td>{purchaseOrderItem.pack_combo_sku_id}</td>
                        )}
                      {errorCount1 == 0 &&
                        currentSkuValidationResponseObject && (
                          <td>{purchaseOrderItem.sku_type}</td>
                        )}

                      <td
                        className={getClassForError(
                          errors.company_code_primary
                        )}
                      >
                        {getTooltipForError(
                          errors.company_code_primary,
                          errorMessages.company_code_primary
                        )}
                        {purchaseOrderItem.company_code_primary}
                      </td>
                      <td
                        className={getClassForError(
                          errors.company_code_secondary
                        )}
                      >
                        {getTooltipForError(
                          errors.company_code_secondary,
                          errorMessages.company_code_secondary
                        )}
                        {purchaseOrderItem.company_code_secondary}
                      </td>
                      <td className={getClassForError(errors.demand)}>
                        {getTooltipForError(
                          errors.demand,
                          errorMessages.demand
                        )}
                        {purchaseOrderItem.demand}
                      </td>
                      <td className={getClassForError(errors.hsn_code)}>
                        {getTooltipForError(
                          errors.hsn_code,
                          errorMessages.hsn_code
                        )}
                        {purchaseOrderItem.hsn_code}
                      </td>
                      <td
                        className={`po-items-title ${getClassForError(
                          errors.title
                        )}`}
                      >
                        {getTooltipForError(errors.title, errorMessages.title)}
                        {purchaseOrderItem.title}
                      </td>
                      <td className={getClassForError(errors.mrp)}>
                        {getTooltipForError(errors.mrp, errorMessages.mrp)}
                        {purchaseOrderItem.mrp}
                      </td>
                      <td className={getClassForError(errors.nlc_tp)}>
                        {getTooltipForError(
                          errors.nlc_tp,
                          errorMessages.nlc_tp
                        )}
                        {purchaseOrderItem.nlc_tp}
                      </td>
                      <td className={getClassForError(errors.tax_rate)}>
                        {getTooltipForError(
                          errors.tax_rate,
                          errorMessages.tax_rate
                        )}
                        {purchaseOrderItem.tax_rate}
                      </td>
                      <td className={getClassForError(errors.size)}>
                        {getTooltipForError(errors.size, errorMessages.size)}
                        {purchaseOrderItem.size}
                      </td>
                      <td className={getClassForError(errors.color)}>
                        {getTooltipForError(errors.color, errorMessages.color)}
                        {purchaseOrderItem.color}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => {
                handlePoSubmission();
              }}
              className="upload-po-final-data mt-4 mb-4"
            >
              Submit
            </Button>
          </div>
        )}
      </div>

      <Modal
        show={deletePurchaseOrder}
        onHide={() => setDeletePurchaseOrder(false)}
        backdrop="static"
        className="alert-dialog"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Partially Created Purchase Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this partially created Purchase Order
          ? Please note that this will be deleted permanently.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">Cancel</Button>
          <Button variant="primary" onClick={() => deleteThisPurchaseOrder()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CompanyPurchaseOrdersAddItems;
